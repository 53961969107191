import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./PublicationComponent.css";

@observer
export class PublicationComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
        return(
            <div className="publication">
                <PageHeader
                    className="page-header"
                    title="Publications"
                />
                    <Paragraph>
                     <h2> Publications from CIRADA team </h2>

                       <ol>
                         <li> <a href="https://ui.adsabs.harvard.edu/abs/2020ascl.soft05003P" target="_blank"><strong>Purcell, C. R.</strong>, <strong>Van Eck, C. L.</strong>, <strong>West, J.</strong>, <strong>Sun, X. H.</strong>, <strong>Gaensler, B. M.</strong>, RM-Tools: Rotation measure (RM) synthesis and Stokes QU-fitting, 2020</a> </li> 
                       <li> <a href="https://ui.adsabs.harvard.edu/abs/2020RNAAS...4..175G" target="_blank"><strong>Gordon, Yjan A.</strong>, <strong>Boyce, Michelle M.</strong>, <strong>O'Dea, Christopher P.</strong>, <strong>Rudnick, Lawrence</strong>, <strong>Andernach, Heinz</strong>, <strong>Vantyghem, Adrian N.</strong>, ..., A Catalog of Very Large Array Sky Survey Epoch 1 Quick Look Components, Sources, and Host Identifications, 2020</a> </li>
                       <li> <a href="https://ui.adsabs.harvard.edu/abs/2021ApJS..255...30G" target="_blank"><strong>Gordon, Yjan A.</strong>, <strong>Boyce, Michelle M.</strong>, <strong>O'Dea, Christopher P.</strong>, <strong>Rudnick, Lawrence</strong>, <strong>Andernach, Heinz</strong>, <strong>Vantyghem, Adrian N.</strong>, ..., A Quick Look at the 3 GHz Radio Sky. I. Source Statistics from the Very Large Array Sky Survey, 2021</a> </li>
<li><a href="https://ui.adsabs.harvard.edu/abs/2021yCat..22550030G" target="_blank"><strong>Gordon, Y. A.</strong>, <strong>Boyce, M. M.</strong>, <strong>O'Dea, C. P.</strong>, <strong>Rudnick, L.</strong>, <strong>Andernach, H.</strong>, <strong>Vantyghem, A. N.</strong>, ..., VizieR Online Data Catalog: VLASS QL Ep.1 Catalog, CIRADA version (Gordon+, 2021), 2021</a> </li>
                        <li> <a href="https://ui.adsabs.harvard.edu/abs/2022PASA...39...59D" target="_blank"><strong>Deg, N.</strong>, <strong>Spekkens, K.</strong>, <strong>Westmeier, T.</strong>, <strong>Reynolds, T. N.</strong>, <strong>Venkataraman, P.</strong>, <strong>Goliath, S.</strong>, ..., WALLABY Pilot Survey: Public release of HI kinematic models for more than 100 galaxies from phase 1 of ASKAP pilot observations, 2022</a> </li>
                        <li> <a href="https://ui.adsabs.harvard.edu/abs/2022PASA...39...58W" target="_blank"><strong>Westmeier, T.</strong>, <strong>Deg, N.</strong>, <strong>Spekkens, K.</strong>, <strong>Reynolds, T. N.</strong>, <strong>Shen, A. X.</strong>, <strong>Gaudet, S.</strong>, ..., WALLABY pilot survey: Public release of H I data for almost 600 galaxies from phase 1 of ASKAP pilot observations, 2022</a> </li>
                       <li> <a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230312830G" target="_blank"><strong>Gordon, Yjan A.</strong>, <strong>Rudnick, Lawrence</strong>, <strong>Andernach, Heinz</strong>, <strong>Morabito, Leah K.</strong>, <strong>O'Dea, Christopher P.</strong>, <strong>Achong, Kaylan-Marie</strong>, ..., A Quick Look at the 3GHz Radio Sky. II. Hunting for DRAGNs in the VLA Sky Survey, 2023</a></li>
  <li> <a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230414355B" target="_blank"><strong>Boyce, M. M.</strong>, <strong>Hopkins, A. M.</strong>, <strong>Riggi, S.</strong>, <strong>Rudnick, L.</strong>, <strong>Ramsay, M.</strong>, <strong>Hale, C. L.</strong>, ..., Hydra I: An extensible multi-source-finder comparison and cataloguing tool, 2023</a></li>
<li> <a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230414357B" target="_blank"><strong>Boyce, M. M.</strong>, <strong>Hopkins, A. M.</strong>, <strong>Riggi, S.</strong>, <strong>Rudnick, L.</strong>, <strong>Ramsay, M.</strong>, <strong>Hale, C. L.</strong>, ..., Hydra II: Characterisation of Aegean, Caesar, ProFound, PyBDSF, and Selavy source finders, 2023</a></li>
<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230311670P" target="_blank"><strong>Parra-Royon, Manuel</strong>, <strong>Shen, Austin</strong>, <strong>Reynolds, Tristan</strong>, <strong>Venkataraman, Parthasarathy</strong>, <strong>Mendoza, María Angeles</strong>, <strong>Sánchez-Exposito, Susana</strong>, ..., Asymmetric distribution of data products from WALLABY, an SKA precursor neutral hydrogen survey, 2023</a></li>
  </ol></Paragraph>
<Paragraph >
<h2><a href="https://ui.adsabs.harvard.edu/search/p_=0&q=full%3A%22CIRADA%22&sort=date%20desc%2C%20bibcode%20desc" style={{textAlign: "center"}}> Link to ADSLABS for latest list of papers with CIRADA in whole text</a></h2>
</Paragraph>

<Paragraph>
                     <h2> Publications by other researchers using CIRADA data products or services </h2>

                   <ol>
              <li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020...12L" target="_blank"><strong>Liu, Adrian</strong>, <strong>Chiang, H. Cynthia</strong>, <strong>Crites, Abigail</strong>, <strong>Sievers, Jonathan</strong>, <strong>Hložek, Renée</strong>, High-redshift 21cm Cosmology in Canada, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.519.4047A" target="_blank"><strong>An, Tao</strong>, <strong>Wang, Ailing</strong>, <strong>Liu, Yuanqi</strong>, <strong>Sotnikova, Yulia</strong>, <strong>Zhang, Yingkang</strong>, <strong>Aditya, J. N. H. S.</strong>, ..., Is the X-ray bright z = 5.5 quasar SRGE J170245.3+130104 a blazar?, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230209754C" target="_blank"><strong>Caleb, M.</strong>, <strong>Driessen, L. N.</strong>, <strong>Gordon, A. C.</strong>, <strong>Tejos, N.</strong>, <strong>Chibueze, J. O.</strong>, <strong>Stappers, B. W.</strong>, ..., Discovery of an as-yet non-repeating fast radio burst with the hallmarks of a repeater, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2020ApJ...891L...6F" target="_blank"><strong>Fonseca, E.</strong>, <strong>Andersen, B. C.</strong>, <strong>Bhardwaj, M.</strong>, <strong>Chawla, P.</strong>, <strong>Good, D. C.</strong>, <strong>Josephy, A.</strong>, ..., Nine New Repeating Fast Radio Burst Sources from CHIME/FRB, 2020</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.519.5922R" target="_blank"><strong>Ridder, M. E.</strong>, <strong>Heinke, C. O.</strong>, <strong>Sivakoff, G. R.</strong>, <strong>Hughes, A. K.</strong>, Radio detections of two unusual cataclysmic variables in the VLA Sky Survey, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...923L..24S" target="_blank"><strong>Stroh, Michael C.</strong>, <strong>Terreran, Giacomo</strong>, <strong>Coppejans, Deanne L.</strong>, <strong>Bright, Joe S.</strong>, <strong>Margutti, Raffaella</strong>, <strong>Bietenholz, Michael F.</strong>, ..., Luminous Late-time Radio Emission from Supernovae Detected by the Karl G. Jansky Very Large Array Sky Survey (VLASS), 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022A&A...663A.129N" target="_blank"><strong>Nanci, C.</strong>, <strong>Giroletti, M.</strong>, <strong>Orienti, M.</strong>, <strong>Migliori, G.</strong>, <strong>Moldón, J.</strong>, <strong>Garrappa, S.</strong>, ..., Observing the inner parsec-scale region of candidate neutrino-emitting blazars, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.516.1865V" target="_blank"><strong>Velović, Velibor</strong>, <strong>Filipović, M. D.</strong>, <strong>Barnes, L.</strong>, <strong>Norris, R. P.</strong>, <strong>Tremblay, C. D.</strong>, <strong>Heald, G.</strong>, ..., Collimation of the kiloparsec-scale radio jets in NGC 2663, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.509.1837P" target="_blank"><strong>Pandge, M. B.</strong>, <strong>Kale, Ruta</strong>, <strong>Dabhade, Pratik</strong>, <strong>Mahato, Mousumi</strong>, <strong>Raychaudhury, Somak</strong>, Giant Metrewave Radio Telescope unveils steep-spectrum antique filaments in the galaxy cluster Abell 725, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.520.4822F" target="_blank"><strong>Fine, Maxwell A.</strong>, <strong>Van Eck, Cameron L.</strong>, <strong>Pratley, Luke</strong>, Correcting bandwidth depolarization by extreme Faraday rotation, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022ApJ...938...43Z" target="_blank"><strong>Zhang, Fabao</strong>, <strong>Shu, Xinwen</strong>, <strong>Sun, Luming</strong>, <strong>Yang, Lei</strong>, <strong>Jiang, Ning</strong>, <strong>Dou, Liming</strong>, ..., Transient Radio Emission from Low-redshift Galaxies at z &lt; 0.3 Revealed by the VLASS and FIRST Surveys, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022A&A...663A.153R" target="_blank"><strong>Retana-Montenegro, E.</strong>, What is the origin of the stacked radio emission in radio-undetected quasars?. Insights from a radio-infrared image stacking analysis, 2022</a> </li>
   <li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...911L...1H" target="_blank"><strong>Hatsukade, B.</strong>, <strong>Tominaga, N.</strong>, <strong>Morokuma, T.</strong>, <strong>Morokuma-Matsui, K.</strong>, <strong>Tamura, Y.</strong>, <strong>Niinuma, K.</strong>, ..., Variability of Late-time Radio Emission in the Superluminous Supernova PTF10hgi, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023A&A...671A..32C" target="_blank"><strong>Capetti, A.</strong>, <strong>Balmaverde, B.</strong>, <strong>Baldi, R. D.</strong>, <strong>Baum, S.</strong>, <strong>Chiaberge, M.</strong>, <strong>Grandi, P.</strong>, ..., The MURALES survey. VII. Optical spectral properties of the nuclei of 3C radio sources at 0.3 &lt; z &lt; 0.82, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022bhaf.confE..26W" target="_blank"><strong>Wójtowicz, Anna</strong>, Radio Loudness of Early-type Galaxies at Low and Very Low Radio Luminosity Range, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022ApJ...927...49C" target="_blank"><strong>Campbell, J. L.</strong>, <strong>Clark, S. E.</strong>, <strong>Gaensler, B. M.</strong>, <strong>Marchal, A.</strong>, <strong>Van Eck, C. L.</strong>, <strong>Deshpande, A. A.</strong>, ..., A Comparison of Multiphase Magnetic Field Tracers in a High Galactic Latitude Region of the Filamentary Interstellar Medium, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021AJ....162...35W" target="_blank"><strong>Wolleben, M.</strong>, <strong>Landecker, T. L.</strong>, <strong>Douglas, K. A.</strong>, <strong>Gray, A. D.</strong>, <strong>Ordog, A.</strong>, <strong>Dickey, J. M.</strong>, ..., The Global Magneto-ionic Medium Survey: A Faraday Depth Survey of the Northern Sky Covering 1280-1750 MHz, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019A&A...627A.142V" target="_blank"><strong>Vardoulaki, E.</strong>, <strong>Jiménez Andrade, E. F.</strong>, <strong>Karim, A.</strong>, <strong>Novak, M.</strong>, <strong>Leslie, S. K.</strong>, <strong>Tisanić, K.</strong>, ..., A closer look at the deep radio sky: Multi-component radio sources at 3 GHz VLA-COSMOS, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.513..886B" target="_blank"><strong>Bruni, G.</strong>, <strong>Bassani, L.</strong>, <strong>Persic, M.</strong>, <strong>Rephaeli, Y.</strong>, <strong>Malizia, A.</strong>, <strong>Molina, M.</strong>, ..., IGR J18249-3243: a new GeV-emitting FR II and the emerging population of high-energy radio galaxies, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019ASPC..523..217L" target="_blank"><strong>Lacy, Mark</strong>, <strong>Chandler, C.</strong>, <strong>Kimball, A.</strong>, <strong>Myers, S.</strong>, <strong>Nyland, K.</strong>, <strong>Witz, S.</strong>, The VLA Sky Survey - Operations, Data Processing and Archiving, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020...28V" target="_blank"><strong>Vanderlinde, Keith</strong>, <strong>Liu, Adrian</strong>, <strong>Gaensler, Bryan</strong>, <strong>Bond, Dick</strong>, <strong>Hinshaw, Gary</strong>, <strong>Ng, Cherry</strong>, ..., The Canadian Hydrogen Observatory and Radio-transient Detector (CHORD), 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.511..280Y" target="_blank"><strong>Yang, Jun</strong>, <strong>Chen, Yongjun</strong>, <strong>Gurvits, Leonid I.</strong>, <strong>Paragi, Zsolt</strong>, <strong>Yang, Aiyuan</strong>, <strong>Yang, Xiaolong</strong>, ..., Structural and spectral properties of Galactic plane variable radio sources, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.512.4210R" target="_blank"><strong>Riseley, C. J.</strong>, <strong>Rajpurohit, K.</strong>, <strong>Loi, F.</strong>, <strong>Botteon, A.</strong>, <strong>Timmerman, R.</strong>, <strong>Biava, N.</strong>, ..., A MeerKAT-meets-LOFAR study of MS 1455.0 + 2232: a 590 kiloparsec 'mini'-halo in a sloshing cool-core cluster, 2022</a> </li>
   <li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.tmp..438F" target="_blank"><strong>Fine, Maxwell A.</strong>, <strong>Van Eck, Cameron L.</strong>, <strong>Pratley, Luke</strong>, Correcting Bandwidth Depolarization by Extreme Faraday Rotation, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...911L...3P" target="_blank"><strong>Pleunis, Z.</strong>, <strong>Michilli, D.</strong>, <strong>Bassa, C. G.</strong>, <strong>Hessels, J. W. T.</strong>, <strong>Naidu, A.</strong>, <strong>Andersen, B. C.</strong>, ..., LOFAR Detection of 110-188 MHz Emission and Frequency-dependent Activity from FRB 20180916B, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022A&A...660A..59M" target="_blank"><strong>Mahato, Mousumi</strong>, <strong>Dabhade, Pratik</strong>, <strong>Saikia, D. J.</strong>, <strong>Combes, Françoise</strong>, <strong>Bagchi, Joydeep</strong>, <strong>Ho, L. C.</strong>, ..., Search and analysis of giant radio galaxies with associated nuclei (SAGAN). III. New insights into giant radio quasars, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021arXiv211213160G" target="_blank"><strong>Gürkan, Gülay</strong>, <strong>Croston, Judith</strong>, <strong>Hardcastle, Martin</strong>, <strong>Mahatma, Vijay</strong>, <strong>Mingo, Beatriz</strong>, <strong>Williams, Wendy</strong>, VLA Snapshot Continuum Survey of FRI Quasar Candidates Selected from the LOFAR Two-Metre Sky Survey (LoTSS), 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.512.6104G" target="_blank"><strong>Gürkan, Gülay</strong>, <strong>Prandoni, I.</strong>, <strong>O'Brien, A.</strong>, <strong>Raja, W.</strong>, <strong>Marchetti, L.</strong>, <strong>Vaccari, M.</strong>, ..., Deep ASKAP EMU Survey of the GAMA23 field: properties of radio sources, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230313152S" target="_blank"><strong>Šnidarić, Iva</strong>, <strong>Jelić, Vibor</strong>, <strong>Mevius, Maaijke</strong>, <strong>Brentjens, Michiel</strong>, <strong>Erceg, Ana</strong>, <strong>Shimwell, Timothy W.</strong>, ..., LOFAR Deep Fields: Probing faint Galactic polarised emission in ELAIS-N1, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022arXiv220805915H" target="_blank"><strong>Harvey, Thomas</strong>, <strong>Maksym, W. Peter</strong>, <strong>Keel, William</strong>, <strong>Koss, Michael</strong>, <strong>Bennert, Vardha N.</strong>, <strong>Chojnowski, S. D.</strong>, ..., Signatures of Feedback in the Spectacular Extended Emission Region of NGC 5972, 2022</a> </li>


<li><a href="https://ui.adsabs.harvard.edu/abs/2021PASA...38...20A" target="_blank"><strong>Anderson, C. S.</strong>, <strong>Heald, G. H.</strong>, <strong>Eilek, J. A.</strong>, <strong>Lenc, E.</strong>, <strong>Gaensler, B. M.</strong>, <strong>Rudnick, Lawrence</strong>, ..., Early Science from POSSUM: Shocks, turbulence, and a massive new reservoir of ionised gas in the Fornax cluster, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022Natur.601..526H" target="_blank"><strong>Hurley-Walker, N.</strong>, <strong>Zhang, X.</strong>, <strong>Bahramian, A.</strong>, <strong>McSweeney, S. J.</strong>, <strong>O'Doherty, T. N.</strong>, <strong>Hancock, P. J.</strong>, ..., A radio transient with unusually slow periodic emission, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022A&A...657A..43H" target="_blank"><strong>Hutschenreuter, S.</strong>, <strong>Anderson, C. S.</strong>, <strong>Betti, S.</strong>, <strong>Bower, G. C.</strong>, <strong>Brown, J. -A.</strong>, <strong>Brüggen, M.</strong>, ..., The Galactic Faraday rotation sky 2020, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...920...45W" target="_blank"><strong>Wang, Ziteng</strong>, <strong>Kaplan, David L.</strong>, <strong>Murphy, Tara</strong>, <strong>Lenc, Emil</strong>, <strong>Dai, Shi</strong>, <strong>Barr, Ewan</strong>, ..., Discovery of ASKAP J173608.2-321635 as a Highly Polarized Transient Point Source with the Australian SKA Pathfinder, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.516.2235C" target="_blank"><strong>Cala, Roldán A.</strong>, <strong>Gómez, José F.</strong>, <strong>Miranda, Luis F.</strong>, <strong>Uscanga, Lucero</strong>, <strong>Breen, Shari L.</strong>, <strong>Dawson, Joanne R.</strong>, ..., Searching for nascent planetary nebulae: OHPNe candidates in the SPLASH survey, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022arXiv221005407C" target="_blank"><strong>Capetti, A.</strong>, <strong>Balmaverde, B.</strong>, <strong>Baldi, R. D.</strong>, <strong>Baum, S.</strong>, <strong>Chiaberge, M.</strong>, <strong>Grandi, P.</strong>, ..., The MURALES survey. VII. Optical spectral properties of the nuclei of 3C radio sources at 0.3&lt;z&lt;0.82, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.tmp..753H" target="_blank"><strong>Hernández-García, L.</strong>, <strong>Panessa, F.</strong>, <strong>Bruni, G.</strong>, <strong>Bassani, L.</strong>, <strong>Arévalo, P.</strong>, <strong>Patiño-Alvarez, V. M.</strong>, ..., Multiwavelength monitoring of the nucleus in PBC J2333.9-2343: the giant radio galaxy with a blazar-like core, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2020PASP..132c5001L" target="_blank"><strong>Lacy, M.</strong>, <strong>Baum, S. A.</strong>, <strong>Chandler, C. J.</strong>, <strong>Chatterjee, S.</strong>, <strong>Clarke, T. E.</strong>, <strong>Deustua, S.</strong>, ..., The Karl G. Jansky Very Large Array Sky Survey (VLASS). Science Case and Survey Design, 2020</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020....9L" target="_blank"><strong>Liu, Adrian</strong>, <strong>Foreman, Simon</strong>, <strong>Padmanabhan, Hamsa</strong>, <strong>Chiang, H. Cynthia</strong>, <strong>Siegel, Seth</strong>, <strong>Wulf, Dallas</strong>, ..., Low-redshift 21cm Cosmology in Canada, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230210932S" target="_blank"><strong>Subrayan, Bhagya M.</strong>, <strong>Milisavljevic, Dan</strong>, <strong>Chornock, Ryan</strong>, <strong>Margutti, Raffaella</strong>, <strong>Alexander, Kate D.</strong>, <strong>Ramakrishnan, Vandana</strong>, ..., Scary Barbie: An Extremely Energetic, Long-Duration Tidal Disruption Event Candidate Without a Detected Host Galaxy at z = 0.995, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020...32D" target="_blank"><strong>Di Francesco, James</strong>, <strong>Chalmers, Dean</strong>, <strong>Denman, Nolan</strong>, <strong>Fissel, Laura</strong>, <strong>Friesen, Rachel</strong>, <strong>Gaensler, Bryan</strong>, ..., The Next Generation Very Large Array, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019ApJ...885L..24C" target="_blank"><strong>CHIME/FRB Collaboration</strong>, <strong>Andersen, B. C.</strong>, <strong>Bandura, K.</strong>, <strong>Bhardwaj, M.</strong>, <strong>Boubel, P.</strong>, <strong>Boyce, M. M.</strong>, ..., CHIME/FRB Discovery of Eight New Repeating Fast Radio Burst Sources, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230203709L" target="_blank"><strong>Loi, F.</strong>, <strong>Brienza, M.</strong>, <strong>Riseley, C. J.</strong>, <strong>Rudnick, L.</strong>, <strong>Boschin, W.</strong>, <strong>Lovisari, L.</strong>, ..., A 600 kpc complex radio source at the center of Abell 3718 discovered by the EMU and POSSUM surveys, 2023</a> </li>
<li><a href="https://ui.adsabs.harvard.edu/abs/2023A&A...670A..35G" target="_blank"><strong>Giarratana, S.</strong>, <strong>Giroletti, M.</strong>, <strong>Spingola, C.</strong>, <strong>Migliori, G.</strong>, <strong>Belladitta, S.</strong>, <strong>Pedani, M.</strong>, Multi-scale VLBI observations of the candidate host galaxy of GRB 200716C, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.tmp..407H" target="_blank"><strong>Hughes, A. K.</strong>, <strong>Sivakoff, G. R.</strong>, <strong>Macpherson, C. E.</strong>, <strong>Miller-Jones, J. C. A.</strong>, <strong>Tetarenko, A. J.</strong>, <strong>Altamirano, D.</strong>, ..., Short Timescale Evolution of the Polarized Radio Jet during V404 Cygni's 2015 Outburst, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022PASA...39...61B" target="_blank"><strong>Broderick, J. W.</strong>, <strong>Drouart, G.</strong>, <strong>Seymour, N.</strong>, <strong>Galvin, T. J.</strong>, <strong>Wright, N.</strong>, <strong>Carnero Rosell, A.</strong>, ..., The GLEAMing of the first supermassive black holes: II. A new sample of high-redshift radio galaxy candidates, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2020Galax...8...53H" target="_blank"><strong>Heald, George</strong>, <strong>Mao, Sui</strong>, <strong>Vacca, Valentina</strong>, <strong>Akahori, Takuya</strong>, <strong>Damas-Segovia, Ancor</strong>, <strong>Gaensler, Bryan</strong>, ..., Magnetism Science with the Square Kilometre Array, 2020</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022ApJ...939...92S" target="_blank"><strong>Shanahan, Russell</strong>, <strong>Stil, Jeroen M.</strong>, <strong>Anderson, Loren</strong>, <strong>Beuther, Henrik</strong>, <strong>Goldsmith, Paul</strong>, <strong>Ott, Jürgen</strong>, ..., Polarized Emission from Four Supernova Remnants in the THOR Survey, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022AJ....164..122M" target="_blank"><strong>McCaffrey, Trevor V.</strong>, <strong>Kimball, Amy E.</strong>, <strong>Momjian, Emmanuel</strong>, <strong>Richards, Gordon T.</strong>, Kiloparsec-scale Radio Structure in z   0.25 Radio-quiet QSOs, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022arXiv221010675E" target="_blank"><strong>Eddins, A.</strong>, <strong>Lee, K. H.</strong>, <strong>Corsi, A.</strong>, <strong>Bartos, I.</strong>, <strong>Marka, Z.</strong>, <strong>Marka, S.</strong>, A search for kilonova radio flares in a sample of Swift/BAT short GRBs, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230311670P" target="_blank"><strong>Parra-Royon, Manuel</strong>, <strong>Shen, Austin</strong>, <strong>Reynolds, Tristan</strong>, <strong>Venkataraman, Parthasarathy</strong>, <strong>Mendoza, María Angeles</strong>, <strong>Sánchez-Exposito, Susana</strong>, ..., Asymmetric distribution of data products from WALLABY, an SKA precursor neutral hydrogen survey, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023A&A...669A.134B" target="_blank"><strong>Belladitta, S.</strong>, <strong>Moretti, A.</strong>, <strong>Caccianiga, A.</strong>, <strong>Dallacasa, D.</strong>, <strong>Spingola, C.</strong>, <strong>Pedani, M.</strong>, ..., A powerful (and likely young) radio-loud quasar at z = 5.3, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021NatAs...5..491H" target="_blank"><strong>Horesh, A.</strong>, <strong>Cenko, S. B.</strong>, <strong>Arcavi, I.</strong>, Delayed radio flares from a tidal disruption event, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230210214G" target="_blank"><strong>Gopal-Krishna</strong>, <strong>Wiita, Paul J.</strong>, <strong>Joshi, Ravi</strong>, <strong>Patra, Dusmanta</strong>, A physically-motivated perspective of the Fanaroff-Riley classification of radio galaxies, 2023</a> </li>
<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.518.1672M" target="_blank"><strong>Miao, C. C.</strong>, <strong>Zhu, W. W.</strong>, <strong>Li, D.</strong>, <strong>Freire, P. C. C.</strong>, <strong>Niu, J. R.</strong>, <strong>Wang, P.</strong>, ..., Arecibo and FAST timing follow-up of 12 millisecond pulsars discovered in Commensal Radio Astronomy FAST Survey, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020...46S" target="_blank"><strong>Spekkens, Kristine</strong>, <strong>Chiang, Cynthia</strong>, <strong>Kothes, Roland</strong>, <strong>Rosolowsky, Erik</strong>, <strong>Rupen, Michael</strong>, <strong>Safi-Harb, Samar</strong>, ..., Canada and the SKA from 2020 - 2030, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...912L...5W" target="_blank"><strong>White, Jacob Aaron</strong>, <strong>Tapia-Vázquez, F.</strong>, <strong>Hughes, A. G.</strong>, <strong>Moór, A.</strong>, <strong>Matthews, B.</strong>, <strong>Wilner, D.</strong>, ..., The First Radio Spectrum of a Rapidly Rotating A-type Star, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.514.6215Y" target="_blank"><strong>Yang, Jun</strong>, <strong>Yang, Xiaolong</strong>, <strong>Wrobel, Joan M.</strong>, <strong>Paragi, Zsolt</strong>, <strong>Gurvits, Leonid I.</strong>, <strong>Ho, Luis C.</strong>, ..., Is there a sub-parsec-scale jet base in the nearby dwarf galaxy NGC 4395?, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020...26R" target="_blank"><strong>Rahman, Mubdi</strong>, <strong>Lang, Dustin</strong>, <strong>Hlozek, Renee</strong>, <strong>Bovy, Jo</strong>, <strong>Perreault-Levasseur, Laurence</strong>, Probing Diverse Phenomena through Data-Intensive Astronomy, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.517L..81O" target="_blank"><strong>Omar, Amitesh</strong>, LOFAR discovery of rare large FR I jets in the low-luminosity radio galaxy NGC 5322, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023ApJ...944..195W" target="_blank"><strong>Wójtowicz, Anna</strong>, <strong>Stawarz, Łukasz</strong>, <strong>Cheung, C. C.</strong>, <strong>Werner, Norbert</strong>, <strong>Rudka, Dominik</strong>, Radio Emission of Nearby Early-type Galaxies in the Low and Very Low Radio Luminosity Range, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230402728R" target="_blank"><strong>Rudnick, Lawrence</strong>, <strong>Cotton, William D.</strong>, Full Resolution Deconvolution of Complex Faraday Spectra, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021MNRAS.502.3814L" target="_blank"><strong>Livingston, J. D.</strong>, <strong>McClure-Griffiths, N. M.</strong>, <strong>Gaensler, B. M.</strong>, <strong>Seta, A.</strong>, <strong>Alger, M. J.</strong>, Heightened Faraday complexity in the inner 1 kpc of the galactic centre, 2021</a> </li>
<li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020...32D" target="_blank"><strong>Di Francesco, James</strong>, <strong>Chalmers, Dean</strong>, <strong>Denman, Nolan</strong>, <strong>Fissel, Laura</strong>, <strong>Friesen, Rachel</strong>, <strong>Gaensler, Bryan</strong>, ..., The Next Generation Very Large Array, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...923L..24S" target="_blank"><strong>Stroh, Michael C.</strong>, <strong>Terreran, Giacomo</strong>, <strong>Coppejans, Deanne L.</strong>, <strong>Bright, Joe S.</strong>, <strong>Margutti, Raffaella</strong>, <strong>Bietenholz, Michael F.</strong>, ..., Luminous Late-time Radio Emission from Supernovae Detected by the Karl G. Jansky Very Large Array Sky Survey (VLASS), 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019ApJ...885L..24C" target="_blank"><strong>CHIME/FRB Collaboration</strong>, <strong>Andersen, B. C.</strong>, <strong>Bandura, K.</strong>, <strong>Bhardwaj, M.</strong>, <strong>Boubel, P.</strong>, <strong>Boyce, M. M.</strong>, ..., CHIME/FRB Discovery of Eight New Repeating Fast Radio Burst Sources, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022ApJ...927...49C" target="_blank"><strong>Campbell, J. L.</strong>, <strong>Clark, S. E.</strong>, <strong>Gaensler, B. M.</strong>, <strong>Marchal, A.</strong>, <strong>Van Eck, C. L.</strong>, <strong>Deshpande, A. A.</strong>, ..., A Comparison of Multiphase Magnetic Field Tracers in a High Galactic Latitude Region of the Filamentary Interstellar Medium, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021NatAs...5..491H" target="_blank"><strong>Horesh, A.</strong>, <strong>Cenko, S. B.</strong>, <strong>Arcavi, I.</strong>, Delayed radio flares from a tidal disruption event, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.510.3794D" target="_blank"><strong>Dobie, D.</strong>, <strong>Stewart, A.</strong>, <strong>Hotokezaka, K.</strong>, <strong>Murphy, Tara</strong>, <strong>Kaplan, D. L.</strong>, <strong>Buckley, D. A. H.</strong>, ..., A comprehensive search for the radio counterpart of GW190814 with the Australian Square Kilometre Array Pathfinder, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023Ap&SS.368...18P" target="_blank"><strong>Perger, Krisztina</strong>, <strong>Frey, Sándor</strong>, <strong>Gabányi, Krisztina Éva</strong>, Radio and mid-infrared properties of the blazar J1419-0838, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022AJ....164..122M" target="_blank"><strong>McCaffrey, Trevor V.</strong>, <strong>Kimball, Amy E.</strong>, <strong>Momjian, Emmanuel</strong>, <strong>Richards, Gordon T.</strong>, Kiloparsec-scale Radio Structure in z   0.25 Radio-quiet QSOs, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021arXiv211213160G" target="_blank"><strong>Gürkan, Gülay</strong>, <strong>Croston, Judith</strong>, <strong>Hardcastle, Martin</strong>, <strong>Mahatma, Vijay</strong>, <strong>Mingo, Beatriz</strong>, <strong>Williams, Wendy</strong>, VLA Snapshot Continuum Survey of FRI Quasar Candidates Selected from the LOFAR Two-Metre Sky Survey (LoTSS), 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022A&A...657A..43H" target="_blank"><strong>Hutschenreuter, S.</strong>, <strong>Anderson, C. S.</strong>, <strong>Betti, S.</strong>, <strong>Bower, G. C.</strong>, <strong>Brown, J. -A.</strong>, <strong>Brüggen, M.</strong>, ..., The Galactic Faraday rotation sky 2020, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230313583B" target="_blank"><strong>Bright, Joe S.</strong>, <strong>Rhodes, Lauren</strong>, <strong>Farah, Wael</strong>, <strong>Fender, Rob</strong>, <strong>van der Horst, Alexander J.</strong>, <strong>Leung, James K.</strong>, ..., Precise Measurements of Self-absorbed Rising Reverse Shock Emission from Gamma-ray Burst 221009A, 2023</a> </li>
<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230210932S" target="_blank"><strong>Subrayan, Bhagya M.</strong>, <strong>Milisavljevic, Dan</strong>, <strong>Chornock, Ryan</strong>, <strong>Margutti, Raffaella</strong>, <strong>Alexander, Kate D.</strong>, <strong>Ramakrishnan, Vandana</strong>, ..., Scary Barbie: An Extremely Energetic, Long-Duration Tidal Disruption Event Candidate Without a Detected Host Galaxy at z = 0.995, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022A&A...660A...2O" target="_blank"><strong>Oei, Martijn S. S. L.</strong>, <strong>van Weeren, Reinout J.</strong>, <strong>Hardcastle, Martin J.</strong>, <strong>Botteon, Andrea</strong>, <strong>Shimwell, Tim W.</strong>, <strong>Dabhade, Pratik</strong>, ..., The discovery of a radio galaxy of at least 5 Mpc, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230311359K" target="_blank"><strong>Kiehlmann, S.</strong>, <strong>Readhead, A. C. S.</strong>, <strong>O'Neill, S.</strong>, <strong>Wilkinson, P. N.</strong>, <strong>Lister, M. L.</strong>, <strong>Liodakis, I.</strong>, ..., Compact Symmetric Objects: A Distinct Population of Jetted Active Galaxies, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021AJ....162...35W" target="_blank"><strong>Wolleben, M.</strong>, <strong>Landecker, T. L.</strong>, <strong>Douglas, K. A.</strong>, <strong>Gray, A. D.</strong>, <strong>Ordog, A.</strong>, <strong>Dickey, J. M.</strong>, ..., The Global Magneto-ionic Medium Survey: A Faraday Depth Survey of the Northern Sky Covering 1280-1750 MHz, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.tmp..407H" target="_blank"><strong>Hughes, A. K.</strong>, <strong>Sivakoff, G. R.</strong>, <strong>Macpherson, C. E.</strong>, <strong>Miller-Jones, J. C. A.</strong>, <strong>Tetarenko, A. J.</strong>, <strong>Altamirano, D.</strong>, ..., Short Timescale Evolution of the Polarized Radio Jet during V404 Cygni's 2015 Outburst, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020...12L" target="_blank"><strong>Liu, Adrian</strong>, <strong>Chiang, H. Cynthia</strong>, <strong>Crites, Abigail</strong>, <strong>Sievers, Jonathan</strong>, <strong>Hložek, Renée</strong>, High-redshift 21cm Cosmology in Canada, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020...26R" target="_blank"><strong>Rahman, Mubdi</strong>, <strong>Lang, Dustin</strong>, <strong>Hlozek, Renee</strong>, <strong>Bovy, Jo</strong>, <strong>Perreault-Levasseur, Laurence</strong>, Probing Diverse Phenomena through Data-Intensive Astronomy, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...912L...5W" target="_blank"><strong>White, Jacob Aaron</strong>, <strong>Tapia-Vázquez, F.</strong>, <strong>Hughes, A. G.</strong>, <strong>Moór, A.</strong>, <strong>Matthews, B.</strong>, <strong>Wilner, D.</strong>, ..., The First Radio Spectrum of a Rapidly Rotating A-type Star, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.520.4822F" target="_blank"><strong>Fine, Maxwell A.</strong>, <strong>Van Eck, Cameron L.</strong>, <strong>Pratley, Luke</strong>, Correcting bandwidth depolarization by extreme Faraday rotation, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022ApJ...929..184S" target="_blank"><strong>Somalwar, Jean J.</strong>, <strong>Ravi, Vikram</strong>, <strong>Dong, Dillon</strong>, <strong>Graham, Matthew</strong>, <strong>Hallinan, Gregg</strong>, <strong>Law, Casey</strong>, ..., The Nascent Milliquasar VT J154843.06+220812.6: Tidal Disruption Event or Extreme Accretion State Change?, 2022</a> </li>
<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.511..280Y" target="_blank"><strong>Yang, Jun</strong>, <strong>Chen, Yongjun</strong>, <strong>Gurvits, Leonid I.</strong>, <strong>Paragi, Zsolt</strong>, <strong>Yang, Aiyuan</strong>, <strong>Yang, Xiaolong</strong>, ..., Structural and spectral properties of Galactic plane variable radio sources, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...908L..10H" target="_blank"><strong>Hilmarsson, G. H.</strong>, <strong>Michilli, D.</strong>, <strong>Spitler, L. G.</strong>, <strong>Wharton, R. S.</strong>, <strong>Demorest, P.</strong>, <strong>Desvignes, G.</strong>, ..., Rotation Measure Evolution of the Repeating Fast Radio Burst Source FRB 121102, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230208386M" target="_blank"><strong>Mckinven, R.</strong>, <strong>Gaensler, B. M.</strong>, <strong>Michilli, D.</strong>, <strong>Masui, K.</strong>, <strong>Kaspi, V. M.</strong>, <strong>Su, J.</strong>, ..., Revealing the Dynamic Magneto-ionic Environments of Repeating Fast Radio Burst Sources through Multi-year Polarimetric Monitoring with CHIME/FRB, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2020ASPC..527..213W" target="_blank"><strong>Wang, K. –S.</strong>, <strong>Comrie, A.</strong>, <strong>Harris, P.</strong>, <strong>Moraghan, A.</strong>, <strong>Hsu, S. –C.</strong>, <strong>Pinska, A.</strong>, ..., CARTA: Cube Analysis and Rendering Tool for Astronomy, 2020</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022ApJ...941...93O" target="_blank"><strong>Otter, Justin Atsushi</strong>, <strong>Rowlands, Kate</strong>, <strong>Alatalo, Katherine</strong>, <strong>Leung, Ho-Hin</strong>, <strong>Wild, Vivienne</strong>, <strong>Luo, Yuanze</strong>, ..., Resolved Molecular Gas Observations of MaNGA Post-starbursts Reveal a Tumultuous Past, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.512.6104G" target="_blank"><strong>Gürkan, Gülay</strong>, <strong>Prandoni, I.</strong>, <strong>O'Brien, A.</strong>, <strong>Raja, W.</strong>, <strong>Marchetti, L.</strong>, <strong>Vaccari, M.</strong>, ..., Deep ASKAP EMU Survey of the GAMA23 field: properties of radio sources, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230210214G" target="_blank"><strong>Gopal-Krishna</strong>, <strong>Wiita, Paul J.</strong>, <strong>Joshi, Ravi</strong>, <strong>Patra, Dusmanta</strong>, A physically-motivated perspective of the Fanaroff-Riley classification of radio galaxies, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021A&A...649L...6M" target="_blank"><strong>Marecki, A.</strong>, Restarted activity in the 3C 328 radio galaxy, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.512.4210R" target="_blank"><strong>Riseley, C. J.</strong>, <strong>Rajpurohit, K.</strong>, <strong>Loi, F.</strong>, <strong>Botteon, A.</strong>, <strong>Timmerman, R.</strong>, <strong>Biava, N.</strong>, ..., A MeerKAT-meets-LOFAR study of MS 1455.0 + 2232: a 590 kiloparsec 'mini'-halo in a sloshing cool-core cluster, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.516.2235C" target="_blank"><strong>Cala, Roldán A.</strong>, <strong>Gómez, José F.</strong>, <strong>Miranda, Luis F.</strong>, <strong>Uscanga, Lucero</strong>, <strong>Breen, Shari L.</strong>, <strong>Dawson, Joanne R.</strong>, ..., Searching for nascent planetary nebulae: OHPNe candidates in the SPLASH survey, 2022</a> </li>
<li><a href="https://ui.adsabs.harvard.edu/abs/2019A&A...627A.142V" target="_blank"><strong>Vardoulaki, E.</strong>, <strong>Jiménez Andrade, E. F.</strong>, <strong>Karim, A.</strong>, <strong>Novak, M.</strong>, <strong>Leslie, S. K.</strong>, <strong>Tisanić, K.</strong>, ..., A closer look at the deep radio sky: Multi-component radio sources at 3 GHz VLA-COSMOS, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.519.5723O" target="_blank"><strong>O'Sullivan, S. P.</strong>, <strong>Shimwell, T. W.</strong>, <strong>Hardcastle, M. J.</strong>, <strong>Tasse, C.</strong>, <strong>Heald, G.</strong>, <strong>Carretti, E.</strong>, ..., The Faraday Rotation Measure Grid of the LOFAR Two-metre Sky Survey: Data Release 2, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022A&A...663A.129N" target="_blank"><strong>Nanci, C.</strong>, <strong>Giroletti, M.</strong>, <strong>Orienti, M.</strong>, <strong>Migliori, G.</strong>, <strong>Moldón, J.</strong>, <strong>Garrappa, S.</strong>, ..., Observing the inner parsec-scale region of candidate neutrino-emitting blazars, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.514.6215Y" target="_blank"><strong>Yang, Jun</strong>, <strong>Yang, Xiaolong</strong>, <strong>Wrobel, Joan M.</strong>, <strong>Paragi, Zsolt</strong>, <strong>Gurvits, Leonid I.</strong>, <strong>Ho, Luis C.</strong>, ..., Is there a sub-parsec-scale jet base in the nearby dwarf galaxy NGC 4395?, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022ApJ...939...92S" target="_blank"><strong>Shanahan, Russell</strong>, <strong>Stil, Jeroen M.</strong>, <strong>Anderson, Loren</strong>, <strong>Beuther, Henrik</strong>, <strong>Goldsmith, Paul</strong>, <strong>Ott, Jürgen</strong>, ..., Polarized Emission from Four Supernova Remnants in the THOR Survey, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022ApJ...938...43Z" target="_blank"><strong>Zhang, Fabao</strong>, <strong>Shu, Xinwen</strong>, <strong>Sun, Luming</strong>, <strong>Yang, Lei</strong>, <strong>Jiang, Ning</strong>, <strong>Dou, Liming</strong>, ..., Transient Radio Emission from Low-redshift Galaxies at z &lt; 0.3 Revealed by the VLASS and FIRST Surveys, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021yCat..22550030G" target="_blank"><strong>Gordon, Y. A.</strong>, <strong>Boyce, M. M.</strong>, <strong>O'Dea, C. P.</strong>, <strong>Rudnick, L.</strong>, <strong>Andernach, H.</strong>, <strong>Vantyghem, A. N.</strong>, ..., VizieR Online Data Catalog: VLASS QL Ep.1 Catalog, CIRADA version (Gordon+, 2021), 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.tmp..438F" target="_blank"><strong>Fine, Maxwell A.</strong>, <strong>Van Eck, Cameron L.</strong>, <strong>Pratley, Luke</strong>, Correcting Bandwidth Depolarization by Extreme Faraday Rotation, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.517L..81O" target="_blank"><strong>Omar, Amitesh</strong>, LOFAR discovery of rare large FR I jets in the low-luminosity radio galaxy NGC 5322, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022A&A...663A.153R" target="_blank"><strong>Retana-Montenegro, E.</strong>, What is the origin of the stacked radio emission in radio-undetected quasars?. Insights from a radio-infrared image stacking analysis, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...911L...1H" target="_blank"><strong>Hatsukade, B.</strong>, <strong>Tominaga, N.</strong>, <strong>Morokuma, T.</strong>, <strong>Morokuma-Matsui, K.</strong>, <strong>Tamura, Y.</strong>, <strong>Niinuma, K.</strong>, ..., Variability of Late-time Radio Emission in the Superluminous Supernova PTF10hgi, 2021</a> </li>
<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230311670P" target="_blank"><strong>Parra-Royon, Manuel</strong>, <strong>Shen, Austin</strong>, <strong>Reynolds, Tristan</strong>, <strong>Venkataraman, Parthasarathy</strong>, <strong>Mendoza, María Angeles</strong>, <strong>Sánchez-Exposito, Susana</strong>, ..., Asymmetric distribution of data products from WALLABY, an SKA precursor neutral hydrogen survey, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022bhaf.confE..26W" target="_blank"><strong>Wójtowicz, Anna</strong>, Radio Loudness of Early-type Galaxies at Low and Very Low Radio Luminosity Range, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2020PASP..132c5001L" target="_blank"><strong>Lacy, M.</strong>, <strong>Baum, S. A.</strong>, <strong>Chandler, C. J.</strong>, <strong>Chatterjee, S.</strong>, <strong>Clarke, T. E.</strong>, <strong>Deustua, S.</strong>, ..., The Karl G. Jansky Very Large Array Sky Survey (VLASS). Science Case and Survey Design, 2020</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019ASPC..523..217L" target="_blank"><strong>Lacy, Mark</strong>, <strong>Chandler, C.</strong>, <strong>Kimball, A.</strong>, <strong>Myers, S.</strong>, <strong>Nyland, K.</strong>, <strong>Witz, S.</strong>, The VLA Sky Survey - Operations, Data Processing and Archiving, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.518.1057E" target="_blank"><strong>El-Badry, Kareem</strong>, <strong>Rix, Hans-Walter</strong>, <strong>Quataert, Eliot</strong>, <strong>Howard, Andrew W.</strong>, <strong>Isaacson, Howard</strong>, <strong>Fuller, Jim</strong>, ..., A Sun-like star orbiting a black hole, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021ApJ...920...45W" target="_blank"><strong>Wang, Ziteng</strong>, <strong>Kaplan, David L.</strong>, <strong>Murphy, Tara</strong>, <strong>Lenc, Emil</strong>, <strong>Dai, Shi</strong>, <strong>Barr, Ewan</strong>, ..., Discovery of ASKAP J173608.2-321635 as a Highly Polarized Transient Point Source with the Australian SKA Pathfinder, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230402728R" target="_blank"><strong>Rudnick, Lawrence</strong>, <strong>Cotton, William D.</strong>, Full Resolution Deconvolution of Complex Faraday Spectra, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023ApJ...944..195W" target="_blank"><strong>Wójtowicz, Anna</strong>, <strong>Stawarz, Łukasz</strong>, <strong>Cheung, C. C.</strong>, <strong>Werner, Norbert</strong>, <strong>Rudka, Dominik</strong>, Radio Emission of Nearby Early-type Galaxies in the Low and Very Low Radio Luminosity Range, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022arXiv220509221M" target="_blank"><strong>Mckinven, R.</strong>, <strong>Gaensler, B. M.</strong>, <strong>Michilli, D.</strong>, <strong>Masui, K.</strong>, <strong>Kaspi, V. M.</strong>, <strong>Bhardwaj, M.</strong>, ..., A Large Scale Magneto-ionic Fluctuation in the Local Environment of Periodic Fast Radio Burst Source, FRB 20180916B, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2020ApJ...891L...6F" target="_blank"><strong>Fonseca, E.</strong>, <strong>Andersen, B. C.</strong>, <strong>Bhardwaj, M.</strong>, <strong>Chawla, P.</strong>, <strong>Good, D. C.</strong>, <strong>Josephy, A.</strong>, ..., Nine New Repeating Fast Radio Burst Sources from CHIME/FRB, 2020</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023MNRAS.519.4047A" target="_blank"><strong>An, Tao</strong>, <strong>Wang, Ailing</strong>, <strong>Liu, Yuanqi</strong>, <strong>Sotnikova, Yulia</strong>, <strong>Zhang, Yingkang</strong>, <strong>Aditya, J. N. H. S.</strong>, ..., Is the X-ray bright z = 5.5 quasar SRGE J170245.3+130104 a blazar?, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2019clrp.2020...54W" target="_blank"><strong>West, Jennifer</strong>, <strong>Brown, Jo-Anne</strong>, <strong>Gaensler, Bryan</strong>, <strong>Hill, Alex S.</strong>, <strong>Irwin, Judith</strong>, <strong>Kothes, Roland</strong>, ..., Cosmic Magnetism, 2019</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023A&A...672A..28L" target="_blank"><strong>Loi, F.</strong>, <strong>Brienza, M.</strong>, <strong>Riseley, C. J.</strong>, <strong>Rudnick, L.</strong>, <strong>Boschin, W.</strong>, <strong>Lovisari, L.</strong>, ..., A 600 kpc complex radio source at the center of Abell 3718 discovered by the EMU and POSSUM surveys, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022MNRAS.513..886B" target="_blank"><strong>Bruni, G.</strong>, <strong>Bassani, L.</strong>, <strong>Persic, M.</strong>, <strong>Rephaeli, Y.</strong>, <strong>Malizia, A.</strong>, <strong>Molina, M.</strong>, ..., IGR J18249-3243: a new GeV-emitting FR II and the emerging population of high-energy radio galaxies, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2021MNRAS.502.3814L" target="_blank"><strong>Livingston, J. D.</strong>, <strong>McClure-Griffiths, N. M.</strong>, <strong>Gaensler, B. M.</strong>, <strong>Seta, A.</strong>, <strong>Alger, M. J.</strong>, Heightened Faraday complexity in the inner 1 kpc of the galactic centre, 2021</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2020ApJ...896L..41C" target="_blank"><strong>Chawla, P.</strong>, <strong>Andersen, B. C.</strong>, <strong>Bhardwaj, M.</strong>, <strong>Fonseca, E.</strong>, <strong>Josephy, A.</strong>, <strong>Kaspi, V. M.</strong>, ..., Detection of Repeating FRB 180916.J0158+65 Down to Frequencies of 300 MHz, 2020</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023A&A...671A..32C" target="_blank"><strong>Capetti, A.</strong>, <strong>Balmaverde, B.</strong>, <strong>Baldi, R. D.</strong>, <strong>Baum, S.</strong>, <strong>Chiaberge, M.</strong>, <strong>Grandi, P.</strong>, ..., The MURALES survey. VII. Optical spectral properties of the nuclei of 3C radio sources at 0.3 &lt; z &lt; 0.82, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023A&A...669A.134B" target="_blank"><strong>Belladitta, S.</strong>, <strong>Moretti, A.</strong>, <strong>Caccianiga, A.</strong>, <strong>Dallacasa, D.</strong>, <strong>Spingola, C.</strong>, <strong>Pedani, M.</strong>, ..., A powerful (and likely young) radio-loud quasar at z = 5.3, 2023</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2022A&A...660A..59M" target="_blank"><strong>Mahato, Mousumi</strong>, <strong>Dabhade, Pratik</strong>, <strong>Saikia, D. J.</strong>, <strong>Combes, Françoise</strong>, <strong>Bagchi, Joydeep</strong>, <strong>Ho, L. C.</strong>, ..., Search and analysis of giant radio galaxies with associated nuclei (SAGAN). III. New insights into giant radio quasars, 2022</a> </li>

<li><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230209754C" target="_blank"><strong>Caleb, M.</strong>, <strong>Driessen, L. N.</strong>, <strong>Gordon, A. C.</strong>, <strong>Tejos, N.</strong>, <strong>Chibueze, J. O.</strong>, <strong>Stappers, B. W.</strong>, ..., Discovery of an as-yet non-repeating fast radio burst with the hallmarks of a repeater, 2023</a> </li>
  </ol>

</Paragraph>


      </div>  );
    }
}
