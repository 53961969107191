import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./HomeComponent.css";

@observer
export class HomeComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
        return(
            <div className="home">
                <PageHeader
                    className="page-header"
                    title=""
                />
                    <Paragraph/>
                    <div style={{ marginTop: "30px" }}>
                    <Paragraph>
                    The Canadian Initiative for Radio Astronomy Data Analysis (CIRADA) is producing science-ready public data products for large surveys being conducted with three telescopes: <a target="_blank" className="attachlink" href="https://public.nrao.edu/telescopes/vla" rel="nofollow">the Very Large Array (VLA)</a>, <a target="_blank" className="attachlink" href="https://www.atnf.csiro.au/projects/askap/index.html" rel="nofollow">the Australian Square Kilometer Array Pathfinder (ASKAP)</a>, and <a target="_blank" className="attachlink" href="https://www.chime-experiment.ca/en" rel="nofollow">the Canadian Hydrogen Intensity Mapping Experiment (CHIME)</a>. These products (e.g., images, cubes, time series spectra, catalogues, databases, alerts, pipeline algorithms, and software tools) are being designed to be immediately usable by professional astronomers and the public, without requiring any expertise in radio astronomy. CIRADA also serves as a pilot project for Canada's planned <a target="_blank" className="attachlink" href="http://www.skatelescope.ca" rel="nofollow">Square Kilometre Array Regional Centre</a>.
                    </Paragraph>
		<div className="row1">
		    <div className="column1">	
                    <img className="img1" src="vlass.jpg"/>
			<br></br>
			<p>Photo of Very Large Array by NRAO(National Radio Astronomy Observatory)</p></div>
			<div className="column1">
                    <img className="img1" src="chime_tel.jpg"/> 
			<br></br>
			<p>Photo of CHIME(Canadian Hydrogen Intesity Mapping)</p></div>
		<div className="column1">
                    <img className="img1" src="csiro_tel.jpg"/> <br></br>
		<p>Photo of the Australian Square Kilometer Array Pathfinder by CSIRO</p></div>
		<br></br>
		</div>
                <br></br>
		<div className="homepage">

		<div className="row2">
		<div className="columna">
                  <Paragraph> <h2>What's New</h2></Paragraph>
			<Paragraph>
                        <ul>

			   <li className="news">
<b>Release of Double Radio Galaxy (DRAGNs) Catalog and Machine Learning-Based Morphological Annotations for VLASS</b><br></br>
<p>CIRADA is excited to announce the release of a double radio galaxy catalog and new machine learning classifications for the VLASS Quick Look epoch 2 data. The Double Radio Galaxy (DRAGNs) Catalog contains over 17,000 double-lobed radio galaxies and supplementary information such as host IDs and, where possible, redshift measurements. We also include  a value-added list of more than 500,000 single-component radio sources.  DRAGN catalogs are now available for each of the existing component catalogs, namely Quick Look 1, 2  and Single Epoch 2. Researchers can access the catalog and the accompanying software <a href="https://cirada.ca/vlasscatalogueql0">here</a>. Detailed descriptions are provided in <a href="https://ui.adsabs.harvard.edu/abs/2023ApJS..267...37G/abstract">Gordon et al., 2023, ApJS, 267, 37</a>.</p>

<p>CIRADA also now introduces morphological annotations of radio sources created using a Self-Organizing Map (SOM) for the epoch 2 data. This unsupervised machine learning technique classifies VLASS sources based on their distinct morphologies into 100 different classes or ‘neurons.’ Such classifications not only provide quality flags to identify bad data but are also beneficial for researchers interested in creating samples based on radio morphologies. Details of potential issues and guidance on such selection are provided in the <a href="https://cirada.ca/vlasscatalogueql0">Catalogue User's Guide</a>.</p>

<p>With this release, we have also corrected any astrometry errors that corrupted the previous versions of the Quick Look epoch 1 catalog.</p>
			  </li>
                             <br></br>
                            <li className="news">
                                The first catalogue release for the <a href='https://cirada.ca/vcsscatalogue'>VCSS Epoch1 Bright Source Catalogue</a>.
                                VCSS is the VLA Low-band Ionosphere and Transient Experiment (VLITE) Commensal Sky Survey,
                                which is a commensal 340-MHz survey performed at the same time as VLASS 2-4 GHz observations.
                                The VCSS Epoch 1 Bright Source Catalogue comprises 52,844 components catalogued above 50 sigma.
                                 Data are recorded on up to 18 VLITE antennas, correlated, and transferred to the
                                 US Naval Research Laboratory (NRL) for automated pipeline processing and archiving.
                            </li>
				<br></br>
                            <li className="news">
                                <a href='https://cirada.ca/wallaby'>The Wallaby(Widefield ASKAP L-band Legacy All-sky Blind survey) pilot phase data release(PDR1) </a> occurred on November 15th, 2022. WALLABY
                                is an ongoing all-sky blind HI survey utilizing ASKAP. It has completed pilot observations of the Hydra, Norma, and NGC 4636 fields.
                                Please <a href = ''>click here</a> to access CIRADA WALLABY PDR1 data portal for all links and documentation related to this pilot data.
                                You can also read the details of the release through the following publications:
                                <ul>
                                    <li className="customIndent">
                                       <a href='https://www.cambridge.org/core/journals/publications-of-the-astronomical-society-of-australia/article/wallaby-pilot-survey-public-release-of-h-i-data-for-almost-600-galaxies-from-phase-1-of-askap-pilot-observations/ADE053E1E08E6415AD08BD9396E8D715'>WALLABY pilot survey: Public release of H I data for almost 600 galaxies from phase 1 of ASKAP pilot observations (Westmeier et al., 2022, PASA, 39, e058)</a>
                                    </li>
                                    <li className="customIndent">
                                    <a href='https://www.cambridge.org/core/journals/publications-of-the-astronomical-society-of-australia/article/wallaby-pilot-survey-public-release-of-hi-kinematic-models-for-more-than-100-galaxies-from-phase-1-of-askap-pilot-observations/DD35C419A062DB1322F1307A660C52E4'>WALLABY Pilot Survey: Public release of HI kinematic models for more than 100 galaxies from phase 1 of ASKAP pilot observations (Deg et al., 2022, PASA, 39, e059)</a>
                                      </li>
                                    </ul></li>
					<br></br>
                                    <li>
                                    WKAPP (WALLABY Kinematic Analysis Proto-Pipeline) is the set of scripts used to generate the WALLABY PDR1 kinematic models.
                                    It utilizes both the FAT and the 3D-Barolo kinematic fitting codes, the CIRADA-developed MCGSuite mock cube generator code, and a
                                    set of custom scripts to generate the final set of kinematic models. The software is now available at the <a href='https://github.com/CIRADA-Tools/WKAPP'>WKAPP</a> GitHub repository.

                                    </li>
					<br></br>
                                    <li className="news">
                                    An enhanced version of <a href="'https://github.com/CIRADA-Tools/cutout_provider_core'>cutout_core_commandline">cutout_core_commandline tool </a>and <a href="">Image Cutout Provider Web Service</a> is available to the public and communities.
                                    The cutout_commandline tool allows you to get cutouts from various sources from the command line.
                                    The newly added docker image is available <a href='https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/data/cutout_provider_core-racs.zip'>here</a>. The zip file contains a tar file of the docker image and a text file with instructions.
                                    You can also use Image Cutout Provider Web Service which allows astronomers to quickly visualize data from multiple surveys at a given
                                    position in the sky and to download the data for further analysis. The RACS survey was recently added for users to check.

                                    </li>



                        </ul>
		</Paragraph>
		</div>
                <div className="columnb">
		<Paragraph>
		<h2>Quick Links </h2>
		</Paragraph>
		<Paragraph>
	        <ul>
		<li> <a href="https://cirada.ca/publication">Publications</a></li>
                <li> <a href="https://cirada.org/Documents/Newsletters">Newsletters</a></li>
                <li> <a href="https://cirada.ca/contact">Contact Us</a></li>
		</ul>
                </Paragraph>
                </div>


		</div>

</div>


                    
                     </div>
            </div>
        );
    }
}
