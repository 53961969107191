import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./NoMatchComponent.css";

@observer
export class NoMatchComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
        return(
            <div className="Contact">
                <PageHeader
                    className="page-header"
                    title="404 Error"
                />
                    <div style={{display: "flex", justifyContent: "center", marginTop: "30px" }}>
                        <Paragraph style={{ fontSize: 20, textAlign: "center", fontWeight: "normal" }}>
                        Sorry! Page Not Found.
                        </Paragraph>
                    </div>
            </div>
        );
    }
}
