import React from "react";
import { observer } from "mobx-react";
import  { Redirect } from "react-router-dom";
import { Button, Card, Form, Input, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AppStore, APIStatus, TabName } from "stores";
import "./LoginComponent.css";

export interface LoginPops {
    defaultRedirect: TabName;
}

@observer
export class LoginComponent extends React.Component<LoginPops> {

    constructor(props: LoginPops) {
        super(props);
    }
    
    private onFinish = (values: any) => {
        const appStore = AppStore.Instance;
        appStore.setLoginStatus(APIStatus.Pending);
        appStore.userLogin(values.username, values.password).then(() => {
            appStore.currentUser();
        });
    };

    public render() {
        const appStore = AppStore.Instance;
        const { Meta } = Card;
        if (appStore.isAuthenticated) {
            let address = appStore.activeTab;
            if (appStore.activeTab === undefined) {
                address = this.props.defaultRedirect;
            }
            return <Redirect to={`/${address}`} />;
        }
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        
        return (
            <div className="login-wrapper">
                <Row className="row-container" justify="center" align="middle">
                    <Spin spinning={appStore.loginStatus === APIStatus.Pending} indicator={antIcon}>
                        <Card bordered={false} cover={<img alt="example" src="./CIRADA.png"/>}>
                            <Meta className="card-meta" description="Please sign-in with CADC credentials" />
                            {appStore.errorMessage !== undefined &&
                            <p className="error-message">{appStore.errorMessage}</p>
                            }
                            <Form
                                {...layout}
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={this.onFinish}
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[{ required: true, message: "Please input your username!" }]}
                                >
                                    <Input />
                                </Form.Item>
                            
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: "Please input your password!" }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            
                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                            <a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/auth/request.html" target="_blank">Request an account from CADC</a><br/>
                            <a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/auth/forgot.html" target="_blank">Reset your password through CADC</a>
                        </Card>
                    </Spin>
                </Row>
            </div>
        );
    }
}