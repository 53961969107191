import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./PeopleComponent.css";

@observer
export class PeopleComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
        const people1 = [
             { name: "CIRADA Executive Team:"},
             { name: "Bryan Gaensler, Director, University of Toronto"},
             { name: "Erik Rosolowsky, Associate Director, University of Alberta"}
               ];
        const people2 = [
             { name: "CIRADA Senior Scientists:"},
             { name: "Professor Bryan Gaensler, University of Toronto"},
             { name: "Professor Erik Rosolowsky, University of Alberta"},
             { name: "Professor Gregory Sivakoff, University of Alberta"},
             { name: "Professor Mark Halpern, University of British Columbia"},
             { name: "Professor Ingrid Stairs, University of British Columbia"},
             { name: "Professor Chris O’Dea, University of Manitoba"},
             { name: "Professor Victoria Kaspi, McGill University"},
             { name: "Professor Kristine Spekkens, Queen’s University"},
             { name: "Professor Ue Li Pen, University of Toronto"}
            ];
        const people3 = [
             { name: "CIRADA Staff:"},
             { name: "Dr. Mathew Dionyssiou, Project Manager, University of Toronto"},
             { name: "Venkat Parthasarathy, Lead Software Developer, University of Toronto"},
             { name: "Tas Siayera, Software Developer, University of Alberta"},
             { name: "Tem Tamre, Software Developer, University of Alberta"},
             { name: "Carli Raul-Omar, Software Developer, University of Alberta"},
             { name: "Dr. Davor Cubranic, Software Developer, University of British Columbia"},
             { name: "Dr. Biny Sebastian, Software Developer, University of Manitoba"},
             { name: "Dr. Chia Min Tan, Software Developer, McGill University"},
             { name: "Dr. Nathan Deg, Software Developer, Queen’s University"},
             { name: "Dr. Lerato Sebakolodi, Software Developer, University of Toronto"},
             { name: "James Willis, Software Developer, University of Toronto"},
             { name: "Dr. Jennifer West, Software Developer, University of Toronto"}

        ];
        const people4 = [
            {name: "Associate Members:"},
            {name: "Dr Casey Law, Scientist, Caltech"},
            {name: "Dr Michiel van Haarlem, Head of NL SKA Office, ASTRON"},
            {name: "Dr JJ Kavelaars, Head of Canadian Astronomy Data Centre, NRC"},
            {name: "Dr Lawrence Rudnick, Professor, University of Minnesota"},
            {name: "Dr Mark Lacy, Project Director (Science Ready Data Products), NRAO"},
            {name: "Dr Russ Taylor, Director, IDIA"},
            {name: "Severin Gaudet, Software Project Manager, NRC"},
            {name: "Dr Shami Chatterjee, Principal Reserach Scientist, Cornell"},
            {name: "Dr Steven Myers, Astronomer, NRAO"},
            {name: "Dr Tom Landecker, Astronomer and Engineer, NRC"},
           ];
        const people5 = [
            {name: "Former Members, Associate Members:"},
            {name: "Dr Jeff Kern, Project Director (Science Ready Data Products), NRAO"},
            { name: "Jean-Paul Bui, Former Lead Software Developer, University of Toronto"},
            { name: "Falon Scheers, Former Software Developer, University of Alberta"},
            { name: "Qi Pang, Former Software Developer, University of Alberta"},
            { name: "Dr. Adrian Vantyghem, Former Software Developer, University of Manitoba"},
            { name: "Dr. Michelle Boyce, Former Software Developer, University of Manitoba"},
            { name: "Dr. Yjan Gordon, Former Software Developer, University of Manitoba"},
            { name: "Dr. Cameron van Eck, Former Software Developer, University of Toronto"}

           ];
        return(
            <div className="People">
                <PageHeader 
                    className="page-header" 
                    title="People Involved"
                />
                    <div >
                    {people1.map(person => (
                    <div style={{display: "flex", justifyContent: "center"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                     {person.name}
                      </Paragraph>
                      </div>
                    ))}
                    </div>
                    <div >
                    {people2.map(person => (
                    <div style={{display: "flex", justifyContent: "center"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                     {person.name}
                      </Paragraph>
                     </div>
                    ))}
                    </div>
                   <div >
                    {people3.map(person => (
                    <div style={{display: "flex", justifyContent: "center"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                     {person.name}
                      </Paragraph>
                     </div>
                    ))}
                    </div>
                    <div >
                    {people4.map(person => (
                    <div style={{display: "flex", justifyContent: "center"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                     {person.name}
                      </Paragraph>
                     </div>
                    ))}
                    </div>
                     <div >
                    {people5.map(person => (
                    <div style={{display: "flex", justifyContent: "center"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                     {person.name}
                      </Paragraph>
                     </div>
                    ))}
                    </div>


            </div>
        );
    }
}
