import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./VLASSpipelineComponent.css";
@observer
export class VLASSpipelineComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
        return(
            <div className="Pipeline">
                <div id="pipeline12"></div>
                <PageHeader 
                    className="site-page-header" 
                    title="VLASS Source Finding Software Pipeline"/>
               <div> 
                <Paragraph>

           This catalogue provides basic component parameters as produced by PyBDSF, flagging of likely spurious detections, identification of likely individual radio sources (either single-component or close-double) and unWISE cross identification where a host for the radio source can be found.

This catalogue is complete in the sense that poor detections are left in but flagged, and documentation is provided with recommended usage. The actual usage however is left at the discretion of the end user.

For identified radio-sources (single-component or close-double) source parameters such as source size, source flux (peak and total) and uncertainties are provided in addition to the individual component parameters. This information is can be conveyed in a component catalogue.

Note that this catalogue includes simple (single-component) to slightly complex (close-double) sources. More complex morphologies exist and are not dealt with by this catalogue, and in these cases no radio-source is determined for the components, although the components are catalogued.

Where an unwise host is identified, the unique unWISE catalogue identifier is provided along with a probability of it being the correct host (provided by the LR code), and W1 magnitude (Vega) and error calculated from the background subtracted flux.
                </Paragraph>
                </div>
                  <div id="pipeline3"></div>
                  <PageHeader
                    className="site-page-header"
                    title="SOM (Self Organizing Maps)"/>
                <div>
                <Paragraph>
                 <h5> SOM TOOLS</h5>
                 The self-organizing map is an unsupervised machine learning algorithm that projects patterns in a dataset onto a 2-dimensional grid of "neurons". It is a useful tool for identifying both common morphologies and rare objects in astronomical images. Instructions on how to train your own SOM are provided in an extensive cookbook.
<ul>
<Link key={1} code={true} target="_blank" href="https://github.com/HITS-AIN/PINK">PINK</Link>: The main tool to train a SOM from a sample of images. It applies rotations and a horizontal flip to ensure the resulting SOM is independent of an object's orientation.
</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://github.com/tjgalvin/pyink">pyink</Link>: A Python wrapper to access and analyze the files used by PINK. Provides helper functions, a tool to create custom annotated masks, and more. 
</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/SOM_Cookbook.pdf">SOM COOKBOOK</Link>: A detailed instruction manual that describes how to train a SOM, how to use the analysis, plotting, and annotation tools in pyink, and provides advice on every step in the process.
</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/CIRADA_SOM_tutorial.tar">Tutorial</Link>: A Jupyter notebook exercise that uses the <Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/CIRADA_SOM_tutorial.tar">Tutorial</Link> as a workable example of how to train and analyze a SOM using the provided tools.
</ul>    
   <h5>The Pipeline </h5>
	   This stage of the pipeline uses a SOM to estimate the probability that each component in the Component catalogue is a false positive detection originating from a sidelobe. The full description of the method used to train the SOM and estimate the sidelobe probabilities are provided in the <Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/Sidelobe_Report.pdf">Sidelobe Report</Link>. These probabilities apply only to components with Peak_to_ring &lt; 3 -- all other components are assigned a null value of -1. All components have been mapped onto the SOM, which provides some morphological information for each component. Here we provide a number of supporting files that may be of interest to those who want to use the SOM directly.
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/Sidelobe_Report.pdf">Sidelobe Report</Link>: Full description of the methods used to estimate P_sidelobe.</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://cirada.ca/pipeline#pipeline3">SOM Tools</Link>: The required tools for working with SOM data.</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/sidelobe_som_labelled.pdf">Labelled</Link> and <Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/som.png">Unlabelled</Link> images of the SOM Pipeline files </ul>
<h5>Pipeline files</h5>
<ul>
<Link key={1} code={true} target="_blank" href="https://github.com/CIRADA-Tools/sidelobe_pipeline">script</Link>: The main pipeline script.
</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/SOM_B3_h10_w10_vlass.bin">SOM Binary</Link>The SOM Binary file</ul>

<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/neuron_info.csv">neuron_info.csv</Link>: Our probabilities for each neuron</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/Pipeline_Instructions.pdf">Pipeline Instructions:</Link> Instructions for running the pipeline.</ul>
<ul> 
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/VLASS1QL_Components_preprocessed.fits">*_preprocessed.fits</Link>: The catalogue of preprocessed components only. Use this when working with the image binary.</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/VLASS1QL_Components_failed.fit">*_faileded.fits</Link>: A catalogue of the components that failed preprocessing.</ul>

<h5>Grid images</h5>
A collection of images that can be used to assess the variety of components that are matched to each neuron. Each of the following links provides, for each of the 100 neurons, a gridded image of 100 randomly-selected components that are matched to the neuron. Both the original image cutouts and the preprocessed images are included for each link.
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/grid_all.tar.gz">Components sampled from the entire catalogue:</Link> Use this to inspect all images that are matched to a neuron, independent of their sidelobe probability.</ul>
<ul><Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/grid_lowPtR.tar.gz">Components with low Peak_to_ring:</Link>Use this to assess the components that have inherited a sidelobe probability.</ul>

<h5>Binary files</h5>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/IMG_VLASS1QL_Components.bin">Image binary</Link>(WARNING: 260 GB!!): All preprocessed VLASS components</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/IMG_VLASS1QL_Components.bin.records.pkl">Image pkl file:</Link> Tracks the row numbers of the catalogue that preprocessed successfully. Keep this in the same directory as the image binary</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/MAP_VLASS1QL_Components.bin">MAP binary</Link>: Contains the Euclidean distances between an image and each neuron.</ul>
<ul>
<Link key={1} code={true} target="_blank" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/TRANSFORM_VLASS1QL_Components.bin">TRANSFORM binary</Link>: Rotation and flip information required to align an image with each neuron.</ul>



                </Paragraph></div>
               </div>
        
        );
    }
}
