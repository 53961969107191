import React from "react";
import { Route, Switch} from "react-router-dom";
import { observer } from "mobx-react";
import { TabName } from "stores";
import { 
    AboutComponent, 
    VLASSCatalogueQL0Component, 
    HydraComponent,
    WallabyComponent,
    FAQComponent, 
    CataloguePops0,
    NoMatchComponent, 
    LoginComponent, 
    LoginPops, 
    ServiceComponent, 
    HomeComponent, 
    PublicationComponent, 
    ContactComponent, 
    VCSSCatalogueComponent,
    ServicePops, 
    TMcomponent,
    SoftwareComponent,
    VLASSpipelineComponent,
    TMPops
} from "../index";
import "./Router.css";

export interface RouterPops {
    servicePops: ServicePops;
    cataloguePops0: CataloguePops0;
    loginPops: LoginPops;
    tmPops: TMPops;
}

@observer
export class BaseRouter extends React.Component<RouterPops> {
    public render() {
        return( 
            <div className="router-container">
                <Switch>
                <Route exact path="/">
                    <HomeComponent/>
                </Route>
                <Route exact path={`/${TabName.SignIn}`}>
                    <LoginComponent {...this.props.loginPops}/>
                </Route>
                <Route exact path={`/${TabName.Services}`}>
                    <ServiceComponent {...this.props.servicePops}/>
                </Route>
                <Route path={`/${TabName.VLASSCatalogueQL0}`}>
                    <VLASSCatalogueQL0Component {...this.props.cataloguePops0}/>
                </Route>
                <Route path="/catalogues" >
                   <VLASSCatalogueQL0Component {...this.props.cataloguePops0}/>
                 </Route>
                 <Route path="/catalogs" >
                   <VLASSCatalogueQL0Component {...this.props.cataloguePops0}/>
                 </Route>

                <Route path="/hydra"> <HydraComponent/> </Route>
                <Route path="/wallaby"> <WallabyComponent/> </Route>
                <Route path="/vcsscatalogue"> <VCSSCatalogueComponent/> </Route>
                <Route path="/vlasspipeline"> <VLASSpipelineComponent/> </Route>
                <Route path={`/${TabName.About}`} component={AboutComponent}/>
                <Route path={`/${TabName.Software}`} component={SoftwareComponent}/>
                <Route path={`/${TabName.FAQ}`} component={FAQComponent}/>
                <Route path={`/${TabName.Publication}`} component={PublicationComponent}/>
                <Route path={`/${TabName.Contact}`} component={ContactComponent}/>
                <Route path={`/${TabName.TransientMarshal}`}>
                    <TMcomponent {...this.props.tmPops} />
                </Route>
                <Route component={NoMatchComponent}/>
                </Switch>
            </div>   
        ); 
    }
}
