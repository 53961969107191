import React from "react";
import { observer } from "mobx-react";
import { Card, Col, Row, Button, PageHeader, Empty} from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Service } from "../../models";
import { APIStatus, ServiceStore, AppStore } from "stores";
import "./ServiceComponent.css";

export interface ServicePops {
    serviceStore: ServiceStore; 
}

@observer
export class ServiceComponent extends React.Component<ServicePops> {

    constructor(props: ServicePops) {
        super(props);
        this.props.serviceStore.getDataset();
    }

    private openInNewTab(service: Service) {
        if (service.active) {
            window.open(service.link, "_blank");
        }
    }

    private renderCard = (service: Service, key: number) => {
        // ant UI bug with disabled
        // gutter bug https://github.com/ant-design/ant-design/issues/4410
        let cardStatus = "Active";
        let statusClassName = "service-card-status-active";
        if (!service.active) {
            cardStatus = "Upcoming";
            statusClassName = "service-card-status-upcoming";
        }
        return(
            <Row key={`row_` + key} gutter={[0, 24]} style={{ marginBottom: 8 }}>
                <Col span={24}>
                    <Card
                        hoverable={true}
                        key={key}
                        title={service.name}
                        bordered={false}
                        onClick={() => this.openInNewTab(service)}
                        extra={<p className={statusClassName}>{cardStatus}</p>}
                    >
                        {service.description}
                        <Button
                            className="service-card-button"
                            type="link"
                            icon={<RightOutlined />}
                            disabled={!service.active}
                            onClick={() => this.openInNewTab(service)}
                            size="large"
                        />
                    </Card>
                </Col>
            </Row>
        );
    }

    private renderServices()  {
        const services = [];
        const dataset = this.props.serviceStore.dataset;
        const status = this.props.serviceStore.status;
        if (dataset.length > 0 && status === APIStatus.Success) {
            for (let index = 0; index < dataset.length; index++) {
                const service = dataset[index];
                if (service.display) {
                    services.push(this.renderCard(service, index));
                }
            }

            return services;
            
        } else {
            return <Empty description={<span>Loading</span>}/>;  
        }
    }

    public render() {
         return(
            <div className="service">
                <PageHeader title="Services"/>
                {this.renderServices()}
            </div>

        );
    }
}
