import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./HydraComponent.css";


@observer
export class HydraComponent extends React.Component {


  public render() {
    return (
      <div>
        <link rel="stylesheet" href="hydra.css" />
        <div className="hydra-main">
          <h1 className="hydra-title">Hydra: An extensible multi-source-finder comparison and cataloguing tool, currently utilizing Aegean, Caesar, ProFound, PyBDSF, and Selavy.</h1>
          <hr />
          <h2>Overview</h2>
          <hr />
          <p>The following diagram shows the Hydra sofware workflow.</p>
          <p /><div className="hydra-fg-pipeline"><img src="EMU_Pipeline.png" width="500px" /></div><p />
          <p>The Hydra software suite consists of the following commands.</p>
          <p /><div className="hydra-tb-generic">
            <table>
              <tbody><tr><th>Tool</th><th>Command</th><th>Function</th></tr>
                <tr><th>Homados</th><td>homados.py</td><td>Provides functionality for creating shallow images with nσ noise (default, n=5), inverting images, and computing RMS, μ, <i>etc.</i> statistics.</td></tr>
                <tr><th>Cerberus</th><td>cerberus.py</td><td>Alows the user to run Hydra's source finders collectively or individualy.</td></tr>
                <tr><th>Typhon</th><td>typhon.py</td><td>Optimizes source-finder RMS and Island parameters to a standard baseline that can be used for comparison purposes. This tool uses Homados and Cerberus.</td></tr>
                <tr><th>Hydra</th><td>hydra.py</td><td>The main tool which glues everthing together, by running Typhon for deep and shallow images, to produce various data products.</td></tr>
              </tbody></table>
          </div><p />
          <p>All commands have builtin help. The Homados, Cerberus, and Typhon command-line interfaces are ancillary tools used for diagnostic purposes. These tools also have library interfaces and are pipelined together, so as to be utilized by Hydra. Hydra is the main tool that glues everything together, by running Typhon for deep and shallow images, and producing the following data products:
          </p><ul>
            <li> Typhon Metrics
              <ul>
                <li> Deep/Shallow Diagnostic Plots of
                  <ul>
                    <li> PRD
                    </li><li> PRD CPU Times
                    </li><li> Residual RMS
                    </li><li> Residual MADFM
                    </li><li> Residual ΣI<sup>2</sup>
                    </li></ul>
                </li><li> Table of deep and shallow optimized RMS and island parameters
                </li></ul>
            </li><li> Deep/Shallow Catalogues
              <ul>
                <li> Source finder Catalogues
                </li><li> Cluster Catalogue
                </li><li> Clump Catalogue
                </li></ul>
            </li><li> Optional Simulated Input Catalogue
            </li><li> Deep/Shallow Cutouts
              <ul>
                <li> Un/annotated Images
                </li><li> Un/annotated Residual Images
                </li></ul>
            </li><li> Diagnostic Plots
              <ul>
                <li> Clump Size Distributions
                </li><li> Detections <i>vs.</i> S/N
                </li><li> Completeness <i>vs.</i> S/N
                </li><li> Reliability <i>vs.</i> S/N
                </li><li> S<sub>out</sub>/S<sub>in</sub> <i>vs.</i> S/N
                </li><li> False-Positive <i>vs.</i> S/N
                </li></ul>
            </li><li> wrt injected and deep sources
            </li><li> Local Web-browser Tool
            </li></ul>
          <p>All of this information is stored in a tarball. With the exception of the source finder, simulated input, and clump catalogues, the local web-browser tool allows the user to explore all of these data products, and is accessible through an <code className="hydra-tt">index.html</code> file in the main tarfile directory.</p>
          <hr />
          <h2>Software</h2>
          <hr />
          <p>The following is the help output of the Hydra tool.</p>
          <pre className="hydra-pre">{"    "}$ python hydra.py --help{"\n"}{"    "}Usage: hydra.py [OPTIONS] FITS_FILE{"\n"}{"\n"}{"      "}Performs deep-shallow analaysis.{"\n"}{"\n"}{"    "}Options:{"\n"}{"      "}--catalogues-yml TEXT{"  "}YML file pointing to catalogoues to match.{"\n"}{"      "}--bypass-archived{"      "}Use FITS_FILE_PREFIX.hydra.tar.gz archive.{"\n"}{"      "}--use TEXT{"             "}Use FITS_FILE_PREFIX.typhon.tar.gz optimization.{"\n"}{"      "}--help{"                 "}Show this message and exit.{"\n"}{"    "}${"\n"}</pre>
          <p>The <code className="hydra-tt">--catalogues-yml</code> option is for referencing a <code className="hydra-tt">&lt;catalogues&gt;.yml</code> configuration file, which points to catalogues to merge with typhon's catalogue output. This is typically used for injecting simulated sources from a simulated image for completeness and reliability studies, <i>etc.</i> For example, the following Hydra command will process (in the background) a simulated 2x2 simulated FITS image (<code className="hydra-tt">emu_simulated_2x2.fits</code>) and collate the results with the corresponding simulated catalogue (<code className="hydra-tt">art_src_list_2x2deg.fits</code>), defined in the <code className="hydra-tt">art_src_list_2x2deg.yml</code> configuration file (logging the results in <code className="hydra-tt">sim_2x2.log</code>).</p>
          <pre className="hydra-pre">{"    "}$ nohup python hydra.py data_path/emu_simulated_2x2.fits --catalogues-yml data_path/art_src_list_2x2deg.yml &gt; sim_2x2.log 2&gt;&amp;1 &amp;{"\n"}</pre>
          <p>This will generate tarballs from Typhon, for deep (<code className="hydra-tt">emu_simulated_2x2.fits</code>) and shallow (<code className="hydra-tt">emu_simulated_2x2.shallow.fits</code>) images, and Hydra, which contains final results: <i>i.e.</i>,</p>
          <pre className="hydra-pre">{"    "}$ ls -1 data_path/*.tar.gz{"\n"}{"    "}data_path/emu_simulated_2x2.hydra.tar.gz{"\n"}{"    "}data_path/emu_simulated_2x2.shallow.typhon.tar.gz{"\n"}{"    "}data_path/emu_simulated_2x2.typhon.tar.gz{"\n"}{"    "}${"\n"}</pre>
          <p>Untarring <code className="hydra-tt">emu_simulated_2x2.hydra.tar.gz</code> (<i>a la</i>, <code className="hydra-tt">tar xf emu_simulated_2x2.hydra.tar.gz</code>), reveals an <code className="hydra-tt">index.html</code> in the top directory of <code className="hydra-tt">emu_simulated_2x2.hydra_dir</code> (created after untarring), from which one can navigate to launch the Hydra Viewer.</p>
          <p>Running the Hydra command will simply load the <code className="hydra-tt">emu_simulated_2x2.hydra.tar.gz</code> again, if it already exists; otherwiser, it will rebuild it from the Typhon tarballs (failing that, it'll redo the whole run). The <code className="hydra-tt">--bypass-archived</code> forces Hydra to reprocesses the Typhon tarballs. This is intended for software development purposes, and future development.</p>
          <p>Finally, the <code className="hydra-tt">--use</code> option is for running Hydra on an image using the Typhon optimiztion results of a reference image. For example, the following command processing the VLASS image <code className="hydra-tt">vlass_qle1_t12t18_2x2deg_sample.fit</code> using the optimization results of the <code className="hydra-tt">emu_simulated_2x2.fits</code> simulated (which will use using its Typhon tarball results, or generated them).</p>
          <pre className="hydra-pre">{"    "}nohup python hydra.py data_path/vlass_qle1_t12t18_2x2deg_sample.fits --use data_path/emu_simulated_2x2.fits &gt; vlass.log 2&gt;&amp;1 &amp;{"\n"}</pre>
          <p>The software, along with further details, can be found in the <a href="https://gitlab.com/rubiculite/emu_tng_source_finder">Hydra repo</a>.</p>
          <hr />
          <h2>Further Details</h2>
          <hr />
          <p>More comprehensive details can be found in the <a href="hydra_paper.pdf" target="_blank">Hydra paper (Boyce <i>et al</i>, 2021)</a>. The following table contains links to the accompanying data.</p>
          <p /><div className="hydra-tb-generic hydra-tb-paper">
            <table>
              <tbody><tr><th>2°x2° Image Data</th><th>Directory</th><th>Contents</th></tr>
                <tr>
                  <th align="left">Simulated Point Sources</th>
                  <td><a href="https://www.canfar.net/storage/vault/list/cirada/continuum/mboyce/hydra_paper/sim_2x2" target="_blank">sim_2x2</a></td>
                  <td valign="middle">
                    <ul>
                      <li><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/mboyce/hydra_paper/sim_2x2/emu_simulated_2x2.fits" download="emu_simulated_2x2.fits">emu_simulated_2x2.fits</a></li>
                      <li><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/mboyce/hydra_paper/sim_2x2/emu_simulated_2x2.shallow.fits" download="emu_simulated_2x2.shallow.fits">emu_simulated_2x2.shallow.fits</a></li>
                      <li><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/mboyce/hydra_paper/sim_2x2/emu_simulated_2x2.hydra.tar.gz" download="emu_simulated_2x2.hydra.tar.gz">emu_simulated_2x2.hydra.tar.gz</a></li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th align="left">Simulated Point and<br /> Extended Sources</th>
                  <td><a href="https://www.canfar.net/storage/vault/list/cirada/continuum/mboyce/hydra_paper/ext_2x2" target="_blank">ext_2x2</a></td>
                  <td valign="middle">
                    <ul>
                      <li><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/mboyce/hydra_paper/ext_2x2/emu_simulated_ext2x2.fits" download="emu_simulated_ext2x2.fits">emu_simulated_ext2x2.fits</a></li>
                      <li><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/mboyce/hydra_paper/ext_2x2/emu_simulated_ext2x2.shallow.fits" download="emu_simulated_ext2x2.shallow.fits">emu_simulated_ext2x2.shallow.fits</a></li>
                      <li><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/mboyce/hydra_paper/ext_2x2/emu_simulated_ext2x2.hydra.tar.gz" download="emu_simulated_ext2x2.hydra.tar.gz">emu_simulated_ext2x2.hydra.tar.gz</a></li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th align="left">EMU Pilot Sample</th>
                  <td><a href="https://www.canfar.net/storage/vault/list/cirada/continuum/mboyce/hydra_paper/emu_2x2" target="_blank">emu_2x2</a></td>
                  <td valign="middle">
                    <ul>
                      <li><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/mboyce/hydra_paper/emu_2x2/emu_pilot_sample_2x2deg.fits" download="emu_pilot_sample_2x2deg.fits">emu_pilot_sample_2x2deg.fits</a></li>
                      <li><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/mboyce/hydra_paper/emu_2x2/emu_pilot_sample_2x2deg.shallow.fits" download="emu_pilot_sample_2x2deg.shallow.fits">emu_pilot_sample_2x2deg.shallow.fits</a></li>
                      <li><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/mboyce/hydra_paper/emu_2x2/emu_pilot_sample_2x2deg.hydra.tar.gz" download="emu_pilot_sample_2x2deg.hydra.tar.gz">emu_pilot_sample_2x2deg.hydra.tar.gz</a></li>
                    </ul>
                  </td>
                </tr>
              </tbody></table>
          </div><p />
        </div>
      </div>
    );
  }
};
