import { action, computed, observable } from "mobx";
import { BackendService } from "services";
import { Field } from "models";
import { APIStatus } from "stores";

type Cutout = {associations: Array<any>, observations: Array<any>, id: string};

export class TMStore {
    backendService: BackendService;
    @observable componentSets: [];
    @observable searchForm: Field[];
    @observable reaultStatus: APIStatus;
    @observable searchErrors: {};
    @observable count: number;
    @observable pageSize: number;
    // pagination
    @observable paginationStatus: APIStatus;
    // classification
    @observable classificationErrors: {};
    @observable classificationStatus: APIStatus;
    @observable currentId: string;
    // cutout, cached cutout
    @observable componentCutouts: Map<string, Cutout>;

    constructor() {
        this.backendService = new BackendService();
        this.componentSets = [];
        this.searchForm = [];
        this.searchErrors = {};
        this.reaultStatus = APIStatus.Initial;
        this.paginationStatus = APIStatus.Initial;
        this.classificationErrors = {};
        this.classificationStatus = APIStatus.Initial;
        this.componentCutouts = new Map();
        this.pageSize = 10;
        this.backendService.axiosInstance.interceptors.response.use(
            BackendService.responseInterceptor(),
            BackendService.responseErrorInterceptor()
        );
    }
    
    async getForm(token: string | null) {
        try {
            if (token) {
                this.backendService.attachTokenToRequest(token);
            }
            const response = await this.backendService.get("tm/formsearch");
            this.setSearchForm(response.data?.form?.fields);
        } catch (error) {
            console.log(error.response);
        }
    }

    async updateClassification(data: any, id: string) {
        this.setClassificationStatus(APIStatus.Pending);
        this.setCurrentId(id);
        this.setClassificationErrors([]);
        try {
            const response = await this.backendService.post("tm/marshal", data);
            // console.log(response);
            if (response.data?.errors) {
                this.setClassificationStatus(APIStatus.Error);
                this.setClassificationErrors(response.data?.errors);
                if (response.data?.marshal_classification) {
                    this.componentSets.forEach((element, index) => {
                        if (element["id"] === id) {
                            this.updateComponentSetClassification(response.data.marshal_classification, index);
                        }
                    });
                }
            } else if (response.data?.marshal_classification) {
                this.setClassificationStatus(APIStatus.Success);
                this.componentSets.forEach((element, index) => {
                    if (element["id"] === id) {
                        this.updateComponentSetClassification(response.data.marshal_classification, index);
                    }
                });
            }
        } catch (error) {
            console.log("Classification Errors: " + error);
            this.setClassificationStatus(APIStatus.Error);
            this.setClassificationErrors([error.toString()]);
        }
    }

    async search(data: any) {
        this.setComponentSets([]);
        try {
            this.setResultStatus(APIStatus.Pending);
            const response = await this.backendService.post("tm/search", data);
            // console.log(response);
            if (response.data?.errors) {
                this.setResultStatus(APIStatus.Error);
                this.setSearchErrors(response.data?.errors);
            } else if (response.data?.data?.length) {
                this.setResultStatus(APIStatus.Success);
                this.setComponentSets(response.data.data);
                this.setCount(response.headers?.count);
                this.setPageSize(response.headers?.page_size);
            } else if (response.data?.length) {
                // server should return in same formate
                const parse = JSON.parse(response.data.replaceAll(NaN, null).replaceAll("'", " "));
                this.setResultStatus(APIStatus.Success);
                this.setComponentSets(parse?.data);
                this.setCount(response.headers?.count);
                this.setPageSize(response.headers?.page_size);
            } else {
                this.setResultStatus(APIStatus.Success);
                this.setComponentSets([]);
                this.setCount(0);
            }
        } catch (error) {
            console.log("Search Errors: " + error);
            this.setResultStatus(APIStatus.Error);
            this.setSearchErrors([error.toString()]);
        }
    }

    async nextPage(data: any, page: number) {
        try {
            this.setPaginationStatus(APIStatus.Pending);
            const response = await this.backendService.post(`tm/search?page=${page}`, data);
            // console.log(response);
            if (response.data?.errors) {
                this.setPaginationStatus(APIStatus.Error);
                this.setSearchErrors(response.data?.errors);
            } else if (response.data?.data?.length) {
                this.setPaginationStatus(APIStatus.Success);
                this.setComponentSets(response.data.data);
            } else if (response.data?.length) {
                const parse = JSON.parse(response.data.replaceAll(NaN, null).replaceAll("'", " "));
                this.setPaginationStatus(APIStatus.Success);
                this.setComponentSets(parse?.data);
            }
        } catch (error) {
            console.log("Pagination Errors: " + error);
            this.setPaginationStatus(APIStatus.Error);
            this.setSearchErrors([error.toString()]);
        }
    }

    async getCutouts(ids: string[]) {
        const uncachedId = this.getUncachedId(ids);
        if (uncachedId.length) {
            try {
                this.setPaginationStatus(APIStatus.Pending);
                const data = {"component_sets": uncachedId};
                const response = await this.backendService.post(`tm/observations`, data);
                if (response.data?.errors) {
                    this.setPaginationStatus(APIStatus.Error);
                    this.setSearchErrors(response.data?.errors);
                } else if (response?.data?.length) {
                    this.setPaginationStatus(APIStatus.Success);
                    response.data.forEach((cutout: Cutout) => {
                        this.setComponentCutouts(cutout, cutout.id);
                    });
                } else {
                    console.log("Cutout Json formate error");
                }
            } catch (error) {
                console.log("Cutout Errors: " + error);
                this.setPaginationStatus(APIStatus.Error);
                this.setSearchErrors([error.toString()]);
            }   
        }
    }

    private getUncachedId = (ids: string[]): string[] => {
        let uncachedId: string[] = [];
        let cachedId: string[] = [];
        this.componentCutouts.forEach((v, k) => {
            cachedId.push(k);
        });

        ids.forEach(id => {
            if (cachedId.includes(id) === false) {
                uncachedId.push(id);
            }
        });
        return uncachedId;
    }

    // async nextPage(pageNumber: number) {
    //     this.setComponentSets([]);
    //     try {
    //         this.setResultStatus(APIStatus.Pending);
    //         const response = await this.backendService.get("tm/search", `page=${pageNumber}`, {withCredentials: true, crossDomain: true});
    //         console.log(response)
    //     } catch (error) {
    //         console.log("Search Errors: " + error);
    //         this.setResultStatus(APIStatus.Error);
    //     }
    // }

    @action setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }

    @action setComponentCutouts = (cutouts: Cutout, id: string) => {
        this.componentCutouts.set(id, cutouts);
    }

    @action setSearchForm = (fields: any) => {
        this.searchForm = Field.transformJsonToArray(fields);
    }

    @action setSearchErrors = (errors: string[]) => {
        this.searchErrors = errors;
    }

    @action setResultStatus = (status: APIStatus) => {
        if (this.reaultStatus !== status) {
            this.reaultStatus = status;
        }
    }

    @action setComponentSets = (componentSets: []) => {
        this.componentSets = componentSets;
        this.componentSets.forEach((componentSet, i) => {
            const obs = componentSet["observations"] as [];
            const sortedArray = obs.slice().sort((a, b) => {
                const aDate = new Date(a["obs_date"]);
                const bDate = new Date(b["obs_date"]);
                if (aDate < bDate) {
                    return -1;
                } 
                if (aDate > bDate) {
                    return 1;
                }
                return 0;
            });
            this.componentSets[i]["observations"] = sortedArray as never;
        });
    }

    @action updateComponentSetClassification = (marshal: {}, index: number) => {
        const current: any = this.componentSets[index];
        current["marshal_classification"] = marshal;
        this.componentSets[index] = current as never;
    }

    @action setCount = (val: number) => {
        this.count = val;
    }

    @action setPaginationStatus = (status: APIStatus) => {
        this.paginationStatus = status;
    }

    @action setClassificationStatus = (status: APIStatus) => {
        this.classificationStatus = status;
    }

    @action setClassificationErrors = (errors: string[]) => {
        this.classificationErrors = errors;
    }

    @action setCurrentId = (id: string) => {
        this.currentId = id;
    }

    @computed get showSearchSpin(): boolean {
        return this.reaultStatus === APIStatus.Pending;
    }

    @computed get showSearchError(): boolean {
        return this.searchErrors && this.reaultStatus === APIStatus.Error;
    }

    @computed get showSearchResult(): boolean {
        return this.reaultStatus === APIStatus.Success && this.componentSets.length > 0;
    }

    @computed get showPaginationSpin(): boolean {
        return this.paginationStatus === APIStatus.Pending || this.showSearchSpin;
    }

    @computed get showClassificationSpin(): boolean {
        return this.classificationStatus === APIStatus.Pending;
    }

    @computed get showClassificationError(): boolean {
        return this.classificationErrors && this.classificationStatus === APIStatus.Error;
    }

    @computed get dataSize(): number {
        return this.componentSets.length;
    }
}