import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./WallabyComponent.css";

@observer
export class WallabyComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
        return(
            <div className="wallaby">
                <PageHeader
                    className="page-header"
                    title="CIRADA Wallaby Pilot Phase Data Portal"
                />


      <p>Welcome to the WALLABY (Widefield ASKAP L-band Legacy All-sky Blind surveY) pilot phase data portal. WALLABY is an ongoing all-sky blind HI survey utilizing the ASKAP telescope (Australian Square Kilometer Array Pathfinder).  It has completed pilot observations of the Hydra, Norma, and NGC 4636 fields.  This page contains links and documentation for public access to this pilot data.</p>
      <h2>Quick Links</h2>
      <ul>
      <li>
       <p><a href="https://wallaby-survey.org">Wallaby Survey Home Page</a></p>
      </li><li>
      <p><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/community/taptap2/">CADC TAPTAP service</a></p>
      </li><li>
      <p><a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/search/">CADC data search service</a></p>
      </li><li>
      <p><a href="https://www.atnf.csiro.au/observers/data/casdaguide.html#section3.2">CASDA TAP instructions</a></p>
      </li><li>
      <p><a href="https://data.csiro.au/domain/casdaObservation?redirected=true">CASDA data search service</a></p>
      </li>
      <li>
      <p><a href="https://github.com/wallaby-survey/data-releases">Github repo with documentation and scripts to access data from CADC and CASDA</a></p>
      </li>
      </ul>
      <h2>TAP and ADQL access</h2>



      <h3>CASDA TAP service</h3>
      <p>One of the many services offered by CASDA is their TAP query.  A user can access the WALLABY pilot phase I catalogues via their TAP service.
        In order to use this service, it is necessary to have a CASDA account.  Once this is made, it is straightforward to access the catalogues using a service like TOPCAT.

         <a href="https://www.atnf.csiro.au/observers/data/casdaguide.html#section3.2"> CASDA TAP Instructions here</a></p>
         <p>
         <p>
        Example ADQL TAP queries in TOPCAT could look like this:
        <ul>
            <li>
        Source finding catalogue: SELECT TOP 1000 * FROM AS102.wallaby_pilot_dr1_sourcefind_cat_v01</li>
          <li>
        Kinematics catalogue: SELECT TOP 1000 * FROM AS102.wallaby_pilot_dr1_kinmodel_cat_v01</li>
          </ul>
        </p>
         </p>
     <h3>CADC TAPTAP service</h3>
      <p>Like CASD CADC also provides a number of different TAP services.
        Once a user has a CADC account, the WALLABY catalogues can searched through a browser at  <a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/community/taptap2/"> CADC TAP Service </a>
        <a href="https://github.com/wallaby-survey/data-releases/blob/master/WALLABY_CADC_TapTap_Access.pdf">  Instructions here</a>.
      </p>
      <p>
      In CADC, either the source table or the kinematic table can be queried by clicking on “Table”. The two tables are named:
      <ul>
          <li>
       Source finding catalogue: cirada.Wallaby_dr1_source_catalogue
         </li>

       <li>
       Kinematics catalogue: cirada.Wallaby_dr1_kinematic_catalogue</li>
         </ul>

         <p>
          <ul>
              <li>
        Example ADQL TAP queries in CADC could look like this:</li><li>
        Source finding catalogue: SELECT TOP 1000 * FROM cirada.Wallaby_dr1_source_catalogue</li><li>
        Kinematics catalogue: SELECT TOP 1000 * FROM cirada.Wallaby_dr1_kinematic_catalogue</li></ul>
        </p>
      </p>
    <h2>Data Download</h2>
      <h3>CADC Cubelet access</h3>
      <p>It is possible to search for data products and directly download them from the
        <a href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/search/"> CADC data search</a>.  On this page you may search for objects by name, sky location, and more. </p>
        <p> CADC Data Access <a href="https://github.com/wallaby-survey/data-releases/blob/master/WALLABY_CADC_AdvancedSearch_access.pdf">Instructions here</a>.
        </p>

      <h3>CASDA data service</h3>
       <p>
            <a href="https://data.csiro.au/domain/casdaObservation?redirected=true">CASDA Observation Search </a></p>
            <p>
            <a href="https://data.csiro.au/domain/casdaSkymap/">CASDA Skymap Search </a>
       </p>
      <h3>CASDA cutout service</h3>
      <p>
        CASDA contains a cutout service that allows users to extract regions of the large mosaiced cubes. To extract a cutout <a href="https://data.csiro.au/domain/casdaObservation?redirected=true">Instructions for CASDA cutout</a>.
      </p>
      <h2>Data Access Scripts</h2>
      <p>
        In addition to the various direct access methods through a web browsers, users can interact with both the CASDA and CADC repositories using ADQL queries via TOPCAT. An example notebook is available
        <a href="https://github.com/wallaby-survey/data-releases/blob/master/CADC_ADQL_query_WALLABY.ipynb"> here</a>
      </p>
    <h2>Software Access</h2>
      <p>The pilot phase data products were generated using of a number of software tools.</p>
      <h4>MCGSuite</h4>
      <p> MCGSuite (Mock Cube Generator Suite) is a set of three codes designed to generate mock observations of tilted ring models. These mock observations mimic observations of axisymmetric H1 gas disks. The software is available at the <a href="https://github.com/CIRADA-Tools/MCGSuite">MCGSuite</a> Github repository.</p>
      <h4>SoFiA</h4>
      <p> SoFiA (the HI Source Finding Application) is used on the large mosaiced data cubes to generate the source catalogue, source cubelets, and masks.  The software is available at the <a href="https://github.com/SoFiA-Admin/SoFiA-2">SoFiA</a> Github repository.</p>
      <h4>3D-Barolo</h4>
      <p> 3D-Barolo (3D-Based Analysis of Rotating Object via Line Observations) is also a kinematic modelling code designed to fit 3D datacubes. Like FAT, it uses a tilted ring algorithm to generate models, but the implementation of this algorithm is significantly different than FAT's implementation, aloowing the two codes to generate differing models for the same observation. A full description of the 3D-Barolo can be found on its repository. The software is available at the <a href="https://editeodoro.github.io/Bbarolo/">3D-Barolo</a> Github repository.</p>
      <h4>WKAPP</h4>
      <p> WKAPP (WALLABY Kinematic Analysis Proto-Pipeline) is the set of scripts used to generate the pilot data release kinematic models. It utilizes both the FAT and the 3d-Borolo kinematic fitting codes, the MCGSuite mock cube generator code, and a set of custom scripts to generate the final set of kinematic models. The software is available at the <a href="https://github.com/CIRADA-Tools/WKAPP">FAT</a> Github repository.</p>
      <h4>WKAPP</h4>
      <p> FAT (Fully Automatic TiRiFiC) is a kinematic modelling code designed to fit #D datacubes. It uses a tilted ring algorithm to generate the models. A full description is available on the FAT repository. The software is available at the <a href="https://github.com/PeterKamphuis/FAT">FAT</a> Github repository.</p>
      <h4>Kinematic Modelling Scripts</h4>
      <p> The scripts used to run the proto-pipeline can be accessed at the  <a href="https://github.com/NateDeg/WALLABY_PilotPhaseI_KinematicModellingScipts">WKAPP</a> Github repository.</p>
      <h2>Papers and References</h2>
      <p> The WALLABY paper is <a href="https://ui.adsabs.harvard.edu/abs/2020Ap%26SS.365..118K/abstract">Koribalski et al., 2020</a></p>
      <p> The pilot phase I data release paper is <a href="https://www.cambridge.org/core/journals/publications-of-the-astronomical-society-of-australia/article/wallaby-pilot-survey-public-release-of-h-i-data-for-almost-600-galaxies-from-phase-1-of-askap-pilot-observations/ADE053E1E08E6415AD08BD9396E8D715">T.Westmeier et all, 2022</a></p>
      <p> The pilot phase I kinematic models paper is <a href="https://www.cambridge.org/core/journals/publications-of-the-astronomical-society-of-australia/article/wallaby-pilot-survey-public-release-of-hi-kinematic-models-for-more-than-100-galaxies-from-phase-1-of-askap-pilot-observations/DD35C419A062DB1322F1307A660C52E4">N.Deg et al, 2022</a></p>

            </div>
        );
    }
}

