export class Service {
    id: string;
    name: string;
    description: string;
    active: boolean;
    display: boolean;
    link: string;

    constructor(id: string, name: string, description: string, active: boolean, display: boolean, link: string) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.active = active;
        this.display = display;
        this.link = link;
    }

    public updateValue(serviceUpdated: Service) {
        if (this.id !== serviceUpdated.id) {
            return this;
        }
        return {...this, ...serviceUpdated};
    }

}