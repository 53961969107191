import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./SoftwareComponent.css";
@observer
export class SoftwareComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
        return(
            <div className="tools" >
                
                <PageHeader 
                    className="site-page-header" 
                    title="RM-Tools"/>
                <Paragraph>
                    This package provides RM-Synthesis, RM-clean and QU-fitting on polarised radio spectra.
                </Paragraph>
                 <div>
                 <Link key={1} code={true} target="_blank" href="https://pypi.org/project/RM-Tools/">
                            PyPi Link
                        </Link></div>
                   <div>
                  <Link key={1} code={true} target="_blank" href="https://github.com/CIRADA-Tools/RM-Tools">
                            GitHub Link
                        </Link></div>
                 <PageHeader
                    className="site-page-header"
                    title="cutout_core_commandline"/>
                <Paragraph>
                    cutout_core_commandline tool allows you to get cutouts from various sources from the commandline.
                    There is a docker image available at <a target="_blank" href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/data/cutout_provider_core-racs.zip
"> Location of Docker Image </a> The zip file contains a tar file of the docker image and a text file with instructions.
                </Paragraph>

                 <div>
                 <Link key={1} code={true} target="_blank" href="https://github.com/CIRADA-Tools/cutout_provider_core">
                            Github Link
                        </Link></div>



                <PageHeader
                    className="site-page-header"
                    title="CARTA"/>
                <Paragraph>
                    CARTA (Cube Analysis and Rendering Tool for Astronomy), is a next generation image visualization and analysis tool designed for ALMA, VLA, and SKA pathfinders.
                </Paragraph>
                  <div><Link key={1} code={true} target="_blank" href="https://cartavis.github.io/">
                            About CARTA
                        </Link></div>

                 <div>
                 <Link key={1} code={true} target="_blank" href="https://github.com/CARTAvis/carta/releases">
                            Github Link
                        </Link></div>
                 
                  <PageHeader
                    className="site-page-header"
                    title="MCGSuite"/>
                <Paragraph>
                    MCGSuite is a tool for generating simulated observations of the HI disks of mock galaxies using scaling relations and a tilted-ring methodology.
                </Paragraph>
                 <div>
                 <Link key={1} code={true} target="_blank" href="https://github.com/CIRADA-Tools/MCGSuite">
                            Github Link
                        </Link></div>

                 <PageHeader
                    className="site-page-header"
                    title="VLASS Source Finding Software Pipelines"/>
                 <div><Link key={1} code={true} target="_blank" href="http://cirada.ca/vlasspipeline#pipeline12">
                            About VLASS Source Finding Software Pipelines
                        </Link></div>


                    <h3><strong>VLASS Continuum BDP Catlogue generator</strong></h3>
                 
                <Paragraph>
                    This pipeline was used to run PyBDSF source finder on VLASS data to perform source extraction and create a PyBDSF catalogue with basic flags.
                </Paragraph>
                  

                 <div>
                 <Link key={1} code={true} target="_blank" href="https://github.com/CIRADA-Tools/continuum_bdp_catalogue_generator">
                            Github Link
                        </Link></div>

                    <h3><strong>Simple source finder</strong></h3>

                <Paragraph>
                    This pipeline was designed to use output from the VLASS Continuum BDP Catalogue generator to produce a value-added PyBDSF catalogue by performing additional QA, adding image cutout urls, and finding unWISE hosts for compact and isolated sources. 
                </Paragraph>

                 <div>
                 <Link key={1} code={true} target="_blank" href="https://github.com/CIRADA-Tools/vlass_cat">
                            Github Link
                        </Link></div>
   
                <PageHeader 
                    className="site-page-header"
                     title="Self Organizing Maps (uses pyink developed in collaboration with Dr Tim Galvin)"/>

                <Paragraph>
                 We used SOM (Self Organizing Maps) to identify sidelobes and enhance source catalogue. It can also be used to (i) to produce catalogues of double and multiple sources, (ii) to classify radio sources as either complex or simple sources, (iii) to find source orientation, and (iv) as an annotation tool. 

                </Paragraph>
                  <div><Link key={1} code={true} target="_blank" href="http://cirada.ca/vlasspipeline#pipeline3">
                            About Self Organizing Maps
                        </Link></div>

                 <div>
                 <Link key={1} code={true} target="_blank" href="https://github.com/CIRADA-Tools/sidelobe_pipeline">
                            Github Link
                        </Link></div>

                <PageHeader
                    className="site-page-header"
                    title="Hydra"/>
                        <Paragraph>
                    Hydra is a source finder comparison and analysis tool which was used to compare PyBDSF, Selavy, Profound, Aegean, Ceasar( Boyce et al, 2021) using EMU data but can be used to compare any source finder on any dataset.
                </Paragraph>
                 <div>
                 <Link key={1} code={true} target="_blank" href="http://cirada.ca/hydra">
                            About Hydra
                        </Link></div>

                 <div>
                 <Link key={1} code={true} target="_blank" href="https://github.com/CIRADA-Tools/hydra">
                            Github Link
                        </Link></div>
               </div>
        
        );
    }
}
