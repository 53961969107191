import { action, observable } from "mobx";
import { BackendService } from "services";
import {APIStatus} from "stores";

export type FeedbackType = { type_id: string, type: string };

export class CatalogueStore {
    private static staticInstance: CatalogueStore;
    backendService: BackendService;
    @observable feedbackType: Array<FeedbackType>;
    @observable status: APIStatus;
    @observable errorMessage: string | null;

    constructor() {
        this.backendService = new BackendService();
        this.feedbackType = [];
        this.status = APIStatus.Initial;
        this.errorMessage = null;
    }

    static get Instance() {
        return CatalogueStore.staticInstance || new CatalogueStore();
    }

    @action async getFeedbackType() {
        try {
            const response = await this.backendService.get("feedbacktype");
            if (response.status >= 200 && response.status <= 300) {
                this.setFeedbackType(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    @action setFeedbackType(datas: Array<FeedbackType>) {
        if (datas.length) {
            datas.forEach((data: FeedbackType) => {
                this.feedbackType.push({type_id: data.type_id, type: data.type});
            });   
        }
    }

    @action setAPIStatus(status: APIStatus) {
        if (this.status !== status) {
            this.status = status;
        }
    }

    @action setErrorMessage(message: string | null) {
        this.errorMessage = message;
    }

    @action async sendEmail(message: any) {
        try {
            const data = {
                type: this.feedbackType[0]?.type_id,
                username: message.username,
                email: message.email,
                subject: message.subject,
                feedback: message.feedback
            };
            this.setAPIStatus(APIStatus.Pending);
            const response = await this.backendService.post("feedback/", data);            
            if (response.status >= 200 && response.status <= 300) {
                this.setAPIStatus(APIStatus.Success);
                this.setErrorMessage(null);
            } else {
                this.setAPIStatus(APIStatus.Error);
                this.setErrorMessage(response.statusText);
            }
        } catch (error) {            
            this.setAPIStatus(APIStatus.Error);
            this.setErrorMessage(error);
        }
    }
}