import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./AboutComponent.css";

@observer
export class AboutComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
         const people1 = [
             { name: "Bryan Gaensler, Director, University of Toronto"},
             { name: "Erik Rosolowsky, Associate Director, University of Alberta"}
               ];
        const people2 = [
             { name: "Professor Bryan Gaensler, University of Toronto"},
             { name: "Professor Erik Rosolowsky, University of Alberta"},
             { name: "Professor Gregory Sivakoff, University of Alberta"},
             { name: "Professor Mark Halpern, University of British Columbia"},
             { name: "Professor Ingrid Stairs, University of British Columbia"},
             { name: "Professor Chris O’Dea, University of Manitoba"},
             { name: "Professor Victoria Kaspi, McGill University"},
             { name: "Professor Kristine Spekkens, Queen’s University"},
             { name: "Professor Ue Li Pen, University of Toronto"}
            ];
        const people3 = [
             { name: "Susan Xu, Project Manager, University of Toronto"},
             { name: "Tem Tamre, Software Developer, University of Alberta"},
             { name: "Carli Raul-Omar, Software Developer, University of Alberta"},
             { name: "Dr. Davor Cubranic, Software Developer, University of British Columbia"},
             { name: "Dr. Biny Sebastian, Software Developer, University of Manitoba"},
             { name: "Dr. Chia Min Tan, Software Developer, McGill University"},
             { name: "Dr. Nathan Deg, Software Developer, Queen’s University"},
             { name: "Dr. Lerato Sebakolodi, Software Developer, University of Toronto"},
             { name: "James Willis, Software Developer, University of Toronto"},

        ];
        const people4 = [
            {name: "Dr Casey Law, Scientist, Caltech"},
            {name: "Dr Michiel van Haarlem, Head of NL SKA Office, ASTRON"},
            {name: "Dr JJ Kavelaars, Head of Canadian Astronomy Data Centre, NRC"},
            {name: "Dr Lawrence Rudnick, Professor, University of Minnesota"},
            {name: "Dr Mark Lacy, Project Director (Science Ready Data Products), NRAO"},
            {name: "Dr Russ Taylor, Director, IDIA"},
            {name: "Severin Gaudet, Software Project Manager, NRC"},
            {name: "Dr Shami Chatterjee, Principal Reserach Scientist, Cornell"},
            {name: "Dr Steven Myers, Astronomer, NRAO"},
            {name: "Dr Tom Landecker, Astronomer and Engineer, NRC"},
            {name: "Dr Jennifer West, Covington Postdoctoral Fellow, NRC"},
            {name: "Dr Cameron van Eck, Research Fellow, Australian National University"},
            {name: "Dr Yjan Gordon, Research Associate, Dept. of Physics, University of Wisconsin-Madison"},
           ];
         const people5 = [
            { name: "Dr. Mathew Dionyssiou, Project Manager, University of Toronto"},
            { name: "Venkat Parthasarathy, Lead Software Developer, University of Toronto"},
            { name: "Tas Siayera, Software Developer, University of Alberta"},
            { name: "Dr Jeff Kern, Project Director (Science Ready Data Products), NRAO"},
            { name: "Jean-Paul Bui, Former Lead Software Developer, University of Toronto"},
            { name: "Falon Scheers, Former Software Developer, University of Alberta"},
            { name: "Qi Pang, Former Software Developer, University of Alberta"},
            { name: "Dr. Adrian Vantyghem, Former Software Developer, University of Manitoba"},
            { name: "Dr. Michelle Boyce, Former Software Developer, University of Manitoba"},

           ];  
        return(
            <div className="about">
                <PageHeader 
                    className="page-header" 
                    title="CIRADA"
                    subTitle="The Canadian Initiative for Radio Astronomy Data Analysis"
                />
                    <div style={{ marginTop: "30px" }}>

                    <Paragraph>
                        CIRADA is a partership between the <a target="_blank" className="attachlink" href="https://www.utornto.ca" rel="nofollow">University of Toronto</a>, the <a target="_blank" className="attachlink" href="https://www.ualberta.ca" rel="nofollow">University of Alberta</a>, the <a target="_blank" className="attachlink" href="www.umanitoba.ca" rel="nofollow"> University of Manitoba</a>, the <a target="_blank" className="attachlink" href="https://www.ubc.ca" rel="nofollow">University of British Columbia</a>, <a target="_blank" className="attachlink" href="https://www.mcgill.ca" rel="nofollow">McGill University </a>and <a target="_blank" className="attachlink" href="https://www.queens.ca" rel="nofollow">Queen's University</a>, in collaboration with <a target="_blank" className="attachlink" href="https://www.nrc.ca" rel="nofollow">National Research Council of Canada</a>, the <a target="_blank" className="attachlink" href="https://www.nrao.org" rel="nofollow">US National Radio Astronomy Observatory</a>, and <a target="_blank" className="attachlink" href="https://www.csiro.au" rel="nofollow">Australia's Commonwealth Scientific and Industrial Research Organization</a>. CIRADA is funded by a grant from the Canadian Foundation for Innovation 2017 Innovation Fund (Project 35999) and the by the Provinces of Ontario, British Columbia, Alberta, Manitoba and Quebec.
                    </Paragraph></div>
              <h2> People Involved with CIRADA</h2>
              <div ><h3> CIRADA Executive Team:</h3>
                    <ul>
                    {people1.map(person => (
                    <div style={{display: "flex", justifyContent: "left"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                    <li> {person.name}</li>
                      </Paragraph>
                      </div>
                    ))} </ul>
                    </div>
                    <div ><h3> CIRADA Senior Scientists: </h3>
                    <ul>
                    {people2.map(person => (
                    <div style={{display: "flex", justifyContent: "left"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                     <li>{person.name}</li>
                      </Paragraph>
                     </div>
                    ))}</ul>
                    </div>
                   <div > <h3> CIRADA Staff: </h3>
                    <ul>
                    {people3.map(person => (
                    <div style={{display: "flex", justifyContent: "left"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                     <li>{person.name}</li>
                      </Paragraph>
                     </div>
                    ))}</ul>
                    </div>
                    <div ><h3> Associate Members: </h3><ul>
                    {people4.map(person => (
                    <div style={{display: "flex", justifyContent: "left"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                     <li>{person.name}</li>
                      </Paragraph>
                     </div>
                    ))}</ul>
                    </div>
                     <div ><h3> Former Members, Associate Members: </h3><ul>
                    {people5.map(person => (
                    <div style={{display: "flex", justifyContent: "left"}}>
                    <Paragraph style={{ fontSize: 16, textAlign: "center", fontWeight: "normal" }}>
                     <li>{person.name}</li>
                      </Paragraph>
                     </div>
                    ))}</ul>
                    </div>

                
            </div>
        );
    }
}
