import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Form, Input, Button, notification, Spin, Divider, Typography, Tooltip  } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import  Table  from "antd/lib/table";
import { CatalogueStore, APIStatus } from "stores";
import "./VLASSCatalogueQL0Component.css";

export interface CataloguePops0 {
    catalogueStore: CatalogueStore;
}

export declare type IconType0 = "success" | "info" | "error" | "warning";

@observer
export class VLASSCatalogueQL0Component extends React.Component <CataloguePops0> {

    private formRef: FormInstance;
    constructor(props: CataloguePops0) {
        super(props);
        this.props.catalogueStore.getFeedbackType();
    }

    componentDidUpdate(prevProps: CataloguePops0) {
        if (this.props.catalogueStore.status === APIStatus.Success) {
            this.openNotificationWithIcon("success", "Message successfully submitted", "Thanks for your message.", 4 );
            this.formRef.resetFields();
        } else if (this.props.catalogueStore.status === APIStatus.Error) {
            this.openNotificationWithIcon("error", "Sorry, we are not able to submit your message, please try again later.", this.props.catalogueStore.errorMessage as string, 0 );
        }
    }

    private onFinished = (message: any) => {
        this.props.catalogueStore.sendEmail(message.feedbackInfo);
    }

    private openNotificationWithIcon = (type: IconType0, title: string, message: string, duration: number) => {
        notification[type]({
            message: title,
            description: message,
            duration: duration
        });
    };

    private updateformRef = (ref: FormInstance) => {
        this.formRef = ref;
    }

    public render() {
        const layout = {
            labelCol: { flex: "100px" },
            wrapperCol: { flex: 4 },
        };

        const validateMessages = {
            required: "${label} is required!",
            types: {
              email: "${label} is not validate email!",
            }
        };
        const { Paragraph, Link } = Typography;
        const sendingEmail = this.props.catalogueStore.status === APIStatus.Pending;
        const columns = [
            {
            title: 'Catalog',
            dataIndex: 'catalog',
            key: 'catalog',
            },
             {
            title: 'Epoch',
            dataIndex: 'epoch',
            key: 'epoch',
            },
             {
            title: 'Version(catalog, DRAGNHunter)',
            dataIndex: 'version',
            key: 'version',
            },
             {
             title: 'Description',
             dataIndex: 'description',
             key: 'description',
             },
            {
             title: 'Component Table',
             dataIndex: 'component',
             key: 'component',
          
                          
             },
             {
            title: 'Host ID Table',
            dataIndex: 'host',
            key: 'host',
            },
             {
            title: 'Subtile Information table',
            dataIndex: 'subtile',
            key: 'subtile',
            },
            {
            title: 'DRAGNHunter Catalog',
            dataIndex: 'doubles',
            key: 'doubles',
            },



        ];

        const dataSource = [

            {catalog: "QL",
            epoch:"1",
            version: "3,1",
            description: "Quick Look epoch 1 catalog of the entire sky",

            component:<a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/CIRADA_VLASS1QLv3.1_table1_components.csv.gz" download>Data ( ~1 GB )</a>, 
            host: <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/VLASS_QL_comp_catalogues_yg/VLASS1QLCIR_v1/CIRADA_VLASS1QL_table2_hosts.csv.gz" download>Data( ~110 MB )</a>,
            subtile:<a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/VLASS_QL_comp_catalogues_yg/VLASS1QLCIR_v1/CIRADA_VLASS1QL_table3_subtile_info.csv.gz" download>Data ( ~5 MB )</a>,
            doubles: [<a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/sources_ql_epoch1.fits" download> DRAGNHunter_sources (~ 150 MB) ::- </a> , <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/dragns_ql_epoch1.fits" download>-:: DRAGNHunter_dragns (~ 6 MB) </a>]
        },
            {catalog: "QL",
            epoch:"2", 
            version: "2,1", 
            description: "Quick Look epoch 2 catalog of the entire sky",
            component:<a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/CIRADA_VLASS2QLv2_table1_components.csv.gz" download>Data ( ~1 GB)</a> ,
            host: <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/CIRADA_VLASS2QLv1_table2_hosts.csv.gz" download>Data ( ~120 MB )</a>,
            subtile: <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/CIRADA_VLASS2QLv1_table3_subtile.csv.gz" download>Data ( ~5 MB )</a>,
            doubles:  [<a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/sources_ql_epoch2.fits" download> DRAGNHunter_sources (~ 120 MB) ::- </a> , <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/dragns_ql_epoch2.fits" download>-:: DRAGNHunter_dragns (~ 5 MB) </a>]
        },
            {catalog: "SE",
            epoch:"2", 
            version: "2,1", 
            description: "~1000 sq.deg with ~0.1M rows",
            component:<a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/CIRADA_VLASS2SEv2_table1_components.csv.gz" download>Data ( ~34 MB )</a> ,
            host: <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/CIRADA_VLASS2SEv1_table2_hosts.csv.gz" download>Data ( ~4 MB )</a>,
            subtile: <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/CIRADA_VLASS2SEv1_table3_subtile.csv.gz" download>Data ( ~150 KB )</a>,
            doubles:  [<a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/sources_se.fits" download> DRAGNHunter_sources (~ 880 KB) ::- </a> , <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/vlass_data/dragns_se.fits" download>-:: DRAGNHunter_dragns (~ 43 KB) </a>]
        },
            
        ];
        return(
            <div className="Catalogue">
                <PageHeader
                    className="site-page-header"
                    title="VLASS Quick Look and Single Epoch Catalogs"
                    subTitle="Webpage Last Updated - 2023-09-30"
 
                />
           
              
                <p> 

                The catalogs provided here are based on the VLASS quick look (QL) and the single epoch (SE) imaging completed as of September 2022. All three catalogs consist of a Component Table, a Host ID Table, and a Subtile Information Table that provides metadata on the images used to produce the catalog. In addition, the DRAGNHunter table is available for the QL epoch 1 data. The production of these catalogs, the data models, and the known data quality issues are detailed in the <a href = "https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/CIRADA__VLASS_catalogue_documentation_2023_june.pdf">Catalog User Guide</a>, which we strongly urge users to read prior to using the catalogs. The main issues with the data quality include a non-homogeneous on-sky distribution of the rms noise. 
 <br/>
<strong> Data Limitations/Caveats: </strong>
<ul>
<li>A systematic ≈15% underestimation of flux measurements is present for the QL catalogs. We have corrected the astrometric errors that were previously present in the QL epoch one catalog. Consequently, the catalogs are useful for generating source samples and target lists but should be used with extreme caution for further applications.</li>
<li> Essential data quality flagging is included, and we recommend that in most cases, people will want to use catalog entries satisfying <em>Duplicate_flag</em> &lt; 2 and <em>Quality_flag</em> == (0 | 4) and <em>S_code!='E' </em> from the Component Table, and <em>P_Host</em> &gt; 0.8 and <em>Source_reliability_flag</em> == 0 from the Host ID Table.</li>
<li> The single epoch component table contains spectral index information along with errors revised from those produced by NRAO and independent quality flags to select sources with reliable spectral index values. We encourage users to set Alpha_quality_flag == 1 (read Section 3.2 of the <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/CIRADA__VLASS_catalogue_documentation_2023_june.pdf">Catalog User Guide</a>) while using the spectral index values provided in the SE catalog.</li> 
</ul>
                    </p>
                     <p>
                     <strong> What's New? </strong>
                     <br/>
                     <ul> 
                    <li>
                   <strong> Value-added catalog of DRAGNHunter</strong>
                     <br/>
                    We have produced a value-added catalog of Double Radio sources associated with Active Galactic Nuclei (DRAGNs) and single-component radio sources using the VLASS component catalogs. Where possible the likely AllWISE counterpart has been identified for these sources and redshifts have been obtained by querying a number of different optical surveys.
The DRAGNs are identified using an automated algorithm <a href="https://github.com/CIRADA-Tools/vlass_doubles">DRAGNhunter</a> and, as with all automated algorithms, this introduces some false positive detections into the catalog. Contamination from these spurious DRAGNs is &lt; 3% when using the provided quality flag (and up to 11% without). The data are available as two tables (described below) that can be downloaded below or queried via CADC's TAP service. If you make use of this data or the DRAGNhunter code in your research, we ask that you please cite the journal article <a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230312830G/abstract"> A Quick look at the 3GHz Radio Sky. II. Hunting for DRAGNs in the VLA Sky Survey (Y. A. Gordon et al., 2023) </a> which describes the methodology and data in full.

  The table DRAGNhunter_sources contains information on all radio sources identified by DRAGNhunter (single-component and DRAGNs) as well as IR host ID information from  <a href="https://cdsarc.cds.unistra.fr/viz-bin/cat/II/328"> AllWISE</a> and redshift information where available.
For Epoch 1 this consists of 595,375 radio sources. Of these ≈247,000 have host IDs, &gt;  87,000 with redshifts.
The table DRAGNhunter_dragns provides additional information specific to the DRAGNs -- a subset of Table 1. In VLASS Epoch 1 &gt; 17,000 DRAGNs are identified.
                  </li>
               <br/>
                <li>
                <strong> Morphological annotations using Self-Organizing Map (SOM). </strong>
                 With the latest release, we provide the machine learning classifications of each component in the QL 2 and SE catalog based on their morphology using a Self-Organizing Map (SOM). Although the primary goal was to add data quality flags and understand the probability of a component being a sidelobe, these classifications can serve as a valuable resource for users interested in the morphology of VLASS sources. Samples based on morphology can be compiled using the Best Matching Neuron columns in the component catalog. Please refer to the SOM image and sections 3.5 and 3.6 in the <a target = "_blank" className = "urllink" href = "https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/CIRADA__VLASS_catalogue_documentation_2023_june.pdf" rel="nofollow"> Catalog User Guide</a>. Make sure to read  5.3 and 5.4 to learn about issues when selecting a sample.                  

                 </li>
               
                </ul>

                </p>
                   
                   <p className="vspace">
                    <strong> Catalog User Guide - read before accessing data</strong>
                    <br/>
                    A full description of the catalog production,
                    data models and known data quality issues.
                    Users of this catalog are strongly urged to read this document in full prior to accessing the catalog.
                </p>
                 
                <ul><li>
                    <a target="_blank" className="urllink" href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/CIRADA__VLASS_catalogue_documentation_2023_june.pdf" rel="nofollow">
                    CIRADA VLASS catalog User Guide (4MB PDF)</a>
                </li></ul>
             
                <p>
                  The VLASS Catalogs can be accessed via the following routes.
                  <ul>
                       <li>Accessing the CADC youcat TAP service</li>
                          <p><a
                          target="_blank"
                          className="urllink"
                          href="https://ws-cadc.canfar.net/youcat/"
                          rel="nofollow"
                          >https://ws-cadc.canfar.net/youcat/</a>
                          </p>
                       <ul>
                      <li>
                          <p><a
                          target="_blank"
                          className="urllink"
                          href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/community/taptap2/"
                          rel="nofollow"
                          >CADC TAP Query</a>  (select the Service "youcat")
                          </p>
                      </li>
		      <li> 
		      <p> <a target = "_blank" className="urllink" href = "https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/tapquery.ipynb.gz" rel="nofollow" >Python notebook example for youcat tapquery </a> &  <a target = "_blank" className="urllink" href = "https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/tapquery.html" rel="nofollow" >Instructions for tap query. </a></p></li>
                      <li>
                      <p><a
                          target="_blank"
                          className="urllink"
                          href="http://www.star.bris.ac.uk/~mbt/topcat/"
                          rel="nofollow"
                          >TOPCAT</a> and other TAP enabled clients
                          </p>
                      </li>
                       </ul>
                      <li>
                          <p><a
                          target="_blank"
                          className="urllink"
                          href="https://vizier.cds.unistra.fr/viz-bin/VizieR?-source=J/ApJS/255/30"
                          rel="nofollow"
                          >Vizier</a>  Link to Vizier
                         </p>
                      </li>
                      <li>
                          <p><a
                          target="_blank"
                          className="urllink"
                          href="https://astroquery.readthedocs.io/en/latest/cadc/cadc.html"
                          rel="nofollow"
                          >astroquery.cadc</a> module in python
                          </p>
                      </li>

		      <li> Direct download links below</li>
                  </ul>

                </p>

                   <p>
                       <Table dataSource = {dataSource} columns = {columns} bordered pagination = {false}/>
                   </p>
               <p> Column Definitions
                 <ul>
                  <li><a target="_blank" className="urllink" href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/column_definitions.pdf" rel="nofollow">Component Table</a> </li>
                  <li><a target="_blank" className="urllink" href= "https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/VLASS_QL_comp_catalogues_yg/VLASS1QLCIR_v1/VLASS1QLCIR_Column_defs_table2.pdf" rel="nofollow">Host ID Table</a> </li>
                  <li><a target="_blank" className="urllink" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/VLASS_QL_comp_catalogues_yg/VLASS1QLCIR_v1/VLASS1QLCIR_Column_defs_table3.pdf" rel="nofollow">Subtile Information Table</a> </li>
                  <li><a target="_blank" className="urllink" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/VLASS_QL_comp_catalogues_yg/VLASS1QLCIR_v1/VLASS1QLCIR_global_image_parameters.pdf" rel="nofollow">Global Image Parameters</a> </li>
                  <li><a target="_blank" className="urllink" href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/continuum/VLASS_QL_comp_catalogues_yg/VLASS1QLCIR_v1/Additional_Columns_in_SE_Component_Table.pdf" rel="nofollow">Additional columns in SE component table</a> </li>
                    <li><a target="_blank" className="urllink" href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/DRAGN_hunter_columndefinitions.pdf" rel="nofollow">Column definitions for DRAGNHunter</a> </li>
                 </ul>
               </p>
                <p> SOM Resources
                 <ul>
                  <li><a target="_blank" className="urllink" href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/sidelobe_som_labelled.pdf" rel="nofollow">SOM overview (one image for each neuron along with its number)</a> </li>
                  <li><a target="_blank" className="urllink" href="https://www.canfar.net/storage/vault/list/cirada/tutorials/neuronspng" rel="nofollow">Example images for each SOM neuron (100 examples each)</a> & <a target="_blank" className="urllink" href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/neuronspng/neuronpng.zip" rel="nofollow">Download zip file</a> </li>
                  <li><a target="_blank" className="urllink" href="https://www.canfar.net/storage/vault/list/cirada/catalogues/SidelobeProducts" rel="nofollow">Other data products including binaries and images</a> </li>
                  <li><a target="_blank" className="urllink" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/catalogues/SidelobeProducts/Sidelobe_Report.pdf" rel="nofollow">Sidelobe report</a> </li>

                 </ul>
               </p>
               <p>
               We provide two ways to access the cutouts.
             <ul>
               <li>
               <a target = "_blank" className="urllink" href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/get_VLASS_cutout_url_ed.html" rel="nofollow"> Instructions for fetcthing cutout URLS </a> &
               
               
               <a target="_blank" href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/tutorials/get_VLASS_cutout_url_ed.ipynb.gz" download> Notebook for fetching cutout URLS</a>
                       </li>
                <li>
                <a target = "_blank" className ="urllink" href="http://cutouts.cirada.ca" rel="nofollow"> Links to cutout server</a> The cutouts can be downloaded by providing individual coordinates or a batch .csv file. 
               </li></ul>
               </p>
               <p>
                Software used to produce these catalogs can be used as pipelines in sequence.
                The links to the software are available at: <a target="_blank" className="urllink" href="https://cirada.ca/software" rel="nofollow">Link to software</a>

                </p>
 
                <p>
                References:
               </p>
               <p>
                 October 2020, Gordon et al.: 
                 <a target="_blank" className="attachlink" href="https://ui.adsabs.harvard.edu/abs/2020RNAAS...4..175G/abstract" rel="nofollow">
                     A Catalog of very Large Array Sky Survey Epoch 1 Quick Look Components, Sources, and Host identifications
                </a>
               </p>
               <p>
                 August 2021, Gordon et al.: 
                 <a target="_blank" className="attachlink" href="https://ui.adsabs.harvard.edu/abs/2021ApJS..255...30G/abstract" rel="nofollow">
                     A Quick Look at the 3 GHz Radio Sky 1. Source Statistics from the Very Large Array Sky Survey
                </a> </p>
                 <p>
                  March 2023, Gordon et al.:
                 <a target="_blank" className="attachlink" href="https://ui.adsabs.harvard.edu/abs/2023arXiv230312830G/abstract" rel="nofollow">
                   A Quick Look at the 3GHz Radio Sky. II. Hunting for DRAGNs in the VLA Sky Survey
                  </a>
                </p>
                  <p className="vspace"><strong>Version History for Quick Look epoch2 and Single Epoch data</strong><br/>
                <ul>
                <li>
                Version 2 (30-Sep-2023):  Sidelobe probabilities and the best-matching neurons are now present in the component catalogs.
               </li>
                <li>
                 Version 1 (07-Feb-2023): The first version of both Single Epoch and  Quick Look epoch 2 component catalogs does not contain sidelobe probabilities. Note: To access version 1 of the QL2 and SE2 catalog, <a href="https://www.canfar.net/storage/vault/list/cirada/continuum/VLASS_QL_comp_catalogues_yg/VLASS1QLCIR_v1">click here.</a>
</li>


               </ul>
                   <br/>
                </p>
                <p className="vspace"><strong> Version History for DRAGNHunter </strong><br/>
                 <li> Version 1 (30-Sep-2023): First version 
                  </li>
                

                </p>
                <br/>
                <p className="vspace"><strong>Version History for Quick Look epoch1 data</strong><br/>
                <ul>
                 <li>
                 Version 3 (30-Sep-2023):  Older versions of the VLASS images contained astrometric errors of up to 1". These images have now been corrected for the astrometric offsets. In addition, some subtiles were re-imaged to remove ghosts. The entire VLASS pipeline was re-run on the corrected images to produce the corrected versions of the component, subtile, and host ID tables. 

                  </li>
                <li>
                  Version 2 (13-Jul-2021): There are some quality control metrics based on our analysis
                 of the data. Table 1 has been updated to correct an unforeseen issue with the data in
                columns QL_image_RA and QL_image_DEC, referring to the center of the VLASS tile
                 observed - this is distinct from the component RA and DEC, which were unaffected.
                Only QL_image_RA and QL_image_DC in Table 1 were affected, and all other data
                  presented were accurate. For most users, we recommend using components with
                 <em> Duplicate_flag</em> &lt; 2 and <em>Quality_flag</em> == (0 | 4), which returns approximately 1.8M rows.
                This includes sidelobe information added to the component table. Four columns were
                   added to the table. We also have additional files generated. Note: To access the older
               versions of the catalogs <a href="https://www.canfar.net/storage/vault/list/cirada/continuum/VLASS_QL_comp_catalogues_yg/VLASS1QLCIR_v1">click here.</a>

               </li>
                <li>
                 Version 1 (06-Jan-2021): The first catalog published contains 3.3M rows.</li>
          

               </ul>

               <br/>
                
                </p> 
                <br/>
                <Divider orientation="center">Feedback</Divider>
                <p className="vspace">Whilst the major problems with the data quality are known and detailed in the Catalog User Guide,
                this is unlikely to be a comprehensive list of potential issues.
                Should you encounter problems with this website or data problems beyond what are described in the Catalog User Guide then please reach out to Biny (Biny.Sebastian@umanitoba.ca) or Susan (Susan.Xu@utoronto.ca).
                </p>
            </div>
        );
    }
}
