export interface Component {
    id: string;
    ra: string;
    dec: string;
    updatedOn: string;
}

export class User {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    dataJoined: string;
    tmAdmin: boolean;
    tmView: boolean;
    tmMarshal: boolean;
    isStaff: boolean;
    isActive: boolean;

    constructor(
        username: string,
        email: string,
        firstName: string,
        lastName: string,
        dataJoined: string,
        tmAdmin: boolean,
        tmView: boolean,
        tmMarshal: boolean,
        isStaff: boolean,
        isActive: boolean
    ) {
        this.username = username;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.dataJoined = dataJoined;
        this.tmAdmin = tmAdmin;
        this.tmView = tmView;
        this.tmMarshal = tmMarshal;
        this.isStaff = isStaff;
        this.isActive = isActive;
    }
}

export class Field {
    required?: boolean;
    label: string;
    key: string;
    type?: string;
    initial?: string | [];
    minLength?: number;
    maxLength?: number;
    minValue?: number;
    maxValue?: number;
    stepsize?: number;
    placeholder?: string;
    choices?: Array<[]>[];
    inputFormats?: string[];

    constructor(
        label: string, 
        key: string,
        required?: boolean, 
        type?: string, 
        initial?: string | [], 
        minLength?: number, 
        maxLength?: number, 
        minValue?: number,
        maxValue?: number,
        stepsize?: number,
        placeholder?: string,
        choices?: Array<[]>[],
        inputFormats?: string[]) {
            this.label = label ? label : "undefined";
            this.key = key;
            this.required = required;
            this.type = type;
            this.initial = initial;
            this.minLength = minLength;
            this.maxLength = maxLength;
            this.minValue = minValue;
            this.maxValue = maxValue;
            this.stepsize = stepsize;
            this.placeholder = placeholder;
            this.choices = choices;
            this.inputFormats = inputFormats;
    }

    public static transformJsonToArray (fields: any): Field[] {
        const form = [];
        for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
                const obj = fields[key];
                form.push(
                    new Field(
                        obj?.label, 
                        key,
                        obj?.required,
                        obj?.type,
                        obj?.initial,
                        obj?.min_length,
                        obj?.max_length,
                        obj?.min_value,
                        obj?.max_value,
                        obj?.stepsize,
                        obj?.placeholder,
                        obj?.choices,
                        obj?.input_formats,
                    )
                );
            }
        }
        return form;
    }
}