import * as React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { Layout, Menu, Button, Tooltip } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { ServiceStore, AppStore, TabName, CatalogueStore, TMStore } from "./stores";
import { BaseRouter, RouterPops } from "./components";
import "antd/dist/antd.css";
import "./App.css";

const { Header, Footer, Content } = Layout;
const { SubMenu } = Menu;
@observer
export class App extends React.Component {

	@computed get appStore() {
		return AppStore.Instance;
	}

	private handleClick = (e: any) => {
		if (e.key !== TabName.SignOut) {
			this.appStore.setActiveTab(e.key);	
		}
	};

	public render() {
		const appStore = this.appStore;
		let className = "App";
		const routerPops: RouterPops = {
			servicePops: {
				serviceStore: new ServiceStore()
			},
			cataloguePops0: {
				catalogueStore: CatalogueStore.Instance
			},

			loginPops: {
				defaultRedirect: TabName.TransientMarshal
			},
			tmPops: {
				TMStore: new TMStore()
			}

		};

		const selectedKeys = [];
		selectedKeys.push(this.appStore.activeTab as string);

		return (
			<div className={className}>
				<Layout className="layout">
					<Header className="header">
						<a href="https://cirada.ca"><img className="logo" src="./CIRADA.png"/></a>
						<Menu 
							className="header-menu"
							theme="light"
							mode="horizontal"
							defaultSelectedKeys={[TabName.Home]}
							onClick={this.handleClick}
							selectedKeys={selectedKeys}
							overflowedIndicator={<MenuOutlined />}
						>
								<Menu.Item key={TabName.Home}>
									<NavLink to="/">Home</NavLink>
								</Menu.Item>
								<SubMenu  key="aboutMenu" title="About">                                    
									<Menu.Item key={TabName.About}>
										<NavLink to={TabName.About}>About Us</NavLink>
									</Menu.Item>
								{/*	<Menu.Item key={TabName.People}>
										<NavLink to={TabName.People}>People Involved</NavLink>
									</Menu.Item> */}
									<Menu.Item key="CIRADA wiki">
										<a href="https://cirada.org" target="_blank">CIRADA wiki</a>
									</Menu.Item>
								</SubMenu>
                                                                <Menu.Item key={TabName.Services}>
                                                                          <Tooltip placement="left" title="The following services are available: CARTA Visualiation, Image Cutout Provider, RM Image Cutout Provider">
                                                                        <NavLink to={TabName.Services}>Web Services</NavLink></Tooltip>
                                                                </Menu.Item>
                                                                <Menu.Item key={TabName.Software}>
                                                                        <Tooltip placement="left" title="The following software packages are available: RMTools, CARTA, MCGSuite, Contunuum BDP Catalogue generator, vlass_cat, Sidelobe Pipeline">
                                                                        <NavLink to={TabName.Software}>Software</NavLink></Tooltip>
                                                                </Menu.Item>

                                                               {/* <SubMenu key="webservices" title="Web Services">
                                                                 <Menu.Item key={TabName.Services}>
                                                                                <Tooltip placement="left" title="The following services are available: CARTA Visualiation, Image Cutout Provider, RM Image Cutout Provider">
                                                                          <span>
                                                                                <NavLink to={TabName.Services}>Services</NavLink>
                                                                                </span>
                                                                                </Tooltip>
            
                                                                  </Menu.Item>

                                                                </SubMenu>

								<SubMenu  key="servicesandtools" title="Software">
										<Menu.Item key={TabName.Tools}>
										<Tooltip placement="left" title="The following software packages are available: RMTools, CARTA, MCGSuite, Contunuum BDP Catalogue generator, vlass_cat, Sidelobe Pipeline">
									<span>
										<NavLink to={TabName.Tools}>Software</NavLink>
										</span>
										</Tooltip>
										</Menu.Item>
									<Menu.Item key={TabName.TransientMarshal}>
									<NavLink to={TabName.TransientMarshal}>Transient Marshal</NavLink>
									</Menu.Item>
								</SubMenu> */}
								<SubMenu  key="dataproducts" title="Data Products">
								{/*	<SubMenu key="catalogues" title="Catalogues"> */}
										<Menu.Item key={TabName.VLASSCatalogueQL0}>
											<NavLink to={TabName.VLASSCatalogueQL0}>VLASS QL and  SE Catalogs </NavLink>
										</Menu.Item>
                                                                                <Menu.Item key={TabName.Wallaby}>
                                                                                        <NavLink to={TabName.Wallaby}>Wallaby</NavLink>
                                                                                </Menu.Item>
                                                                                 <Menu.Item key={TabName.VCSSCatalogue}>
                                                                                        <NavLink to={TabName.VCSSCatalogue}>VCSSCatalogue</NavLink>
                                                                                </Menu.Item>


                                                                               {/*   <Menu.Item key={TabName.Hydra}>
                                                                                        <NavLink to={TabName.Hydra}>Hydra</NavLink>
                                                                                </Menu.Item> */}

								{/*	</SubMenu> */}
                                                                
								</SubMenu>

								<Menu.Item key={TabName.Publication}>
									<NavLink to={TabName.Publication}>Publications</NavLink>
								</Menu.Item>
								<Menu.Item key={TabName.Contact}>
									<NavLink to={TabName.Contact}>Contact</NavLink>
								</Menu.Item>
								{appStore.isAuthenticated &&
								<Menu.Item key={TabName.SignOut} onClick={() => appStore.clearToken()}>
									<NavLink to={TabName.SignIn}>Sign Out</NavLink>
								</Menu.Item>
								}
						</Menu>
					</Header>
					<Content className="content">
						<BaseRouter {...routerPops}/>
					<Footer className="footer">
                    	<div style={{display: "flex", justifyContent: "center"}}/>
                    <div style={{display: "flex", justifyContent: "center"}}>
                    <div className="row">
                     <div className="column" >
                     <p style={{ fontSize: 14, fontWeight: "bold" }}> CIRADA MEMBERS: </p>
                    <a className="image" href="https://utoronto.ca">
                    <img className="img" src="ut.png"/> </a>
                    <a className="image" href="https://queensu.ca">
                    <img className="img" src="queens.png"/> </a>
                    <a className="image" href="https://ualberta.ca">
                    <img className="img" src="ualberta.png"/> </a>
                    <a className="image" href="https://ubc.ca">
                    <img  className="img" src="ubc.png"/> </a>
                    <a  className="image" href="https://mcgill.ca">
                    <img className="img" src="mcgill.png"/> </a>
                    <a className="image" href="https://umanitoba.ca">
                    <img className="img" src="umanitoba.png"/> </a>
                    </div>
                    <div className="column" >
                    <p style={{ fontSize: 14, fontWeight: "bold" , marginTop: "10px"}}> OUR PARTNERS: </p>
                    <a className="image" href="https://www.cornell.edu">
                    <img className="img" src="cornell.png"/> </a>
                    <a className="image" href="https://astron.nl">
                    <img className="img" src="astron.png"/> </a>
                    <a className="image" href="https://www.csiro.au">
                    <img className="img" src="csiro.png"/> </a>
                    <a className="image" href="https://public.nrao.edu">
                    <img className="img" src="nrao.png"/> </a>
                    <a className="image" href="https://chime-experiment.ca">
                    <img className="img" src="chime.png"/> </a>
                    <a className="image" href="https://nrc.canada.ca/en">
                    <img className="img" src="nrc.png"/> </a>
                    <a className="image" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/">
                    <img className="img" src="CADC.png"/> </a>
                    <a className="image" href="https://www.idia.ac.za/">
                    <img className="img" src="idialogo.png"/></a>
                     <a className="image" href="https://www.caltech.edu">
                    <img className="img" src="CALTECHLOGO.png"/> </a>
                     <a className="image" href="https://www.umn.edu">
                    <img className="img" src="uminnesota.png"/> </a>
                    </div>
                     <div className="column">
                     <p style={{ fontSize: 14, fontWeight: "bold" , marginTop: "10px"}}> FUNDING AGENCIES: </p>
                    <a className="image" href="https://innovation.ca">
                    <img className="img" src="cfi.png"/> </a>
                    <a className="image" href="https://www.ontario.ca/page/ontario-research-fund">
                    <img className="img" src="ontario.png"/> </a>
                    <a className="image" href="https://www.alberta.ca/research-capacity-program.aspx">
                    <img className="img" src="alberta.png"/> </a>
                     <a className="image" href="https://researchmanitoba.ca">
                    <img className="img" src="manitoba.png"/> </a>
                    <a className="image" href="https://www.economie.gouv.qc.ca/accueil">
                    <img className="img" src="quebec.png"/> </a>
                     <a className="image" href="https://www2.gov.bc.ca/gov/content/governments/technology-innovation/bckdf">
                    <img className="img" src="bc.png"/> </a>
                    </div>
                    </div>
                    </div>
                    <p style={{ fontSize: 14, textAlign: "center", marginTop: "20px" }}>    Copyright © 2018-2023 The Canadian Initiative for Radio Astronomy Data Analysis</p>
					</Footer>
					</Content>
				</Layout>
		  	</div>
		);
	}
}
