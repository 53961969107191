import { action, observable, computed } from "mobx";
import { BackendService } from "services";
import { APIStatus } from "./ServiceStore";
import { User } from "models";

export enum TabName {
    Home = "home",
    About = "about",
    Services = "services",
    VLASSCatalogueQL0 = "vlasscatalogueql0",
    Hydra = "hydra",
    Publication = "publication",
    Contact = "contact",
    FAQ = "faq",
    Wallaby = "wallaby",
    VCSSCatalogue = "vcsscatalogue",
    Software = "software",
    TransientMarshal = "tm",
    TransientMarshalView = "view",
    SignOut = "logout",
    SignIn = "login"
}

enum Token {
    Access = "access",
    Refresh = "refresh"
}

export class AppStore {
    private static staticInstance: AppStore;
    private TabNames = new Map<string, TabName>([
        ["", TabName.Home],
        ["about", TabName.About],
        ["services", TabName.Services],
        ["faq", TabName.FAQ],
	["vlasscatalogueql0", TabName.VLASSCatalogueQL0],
        ["hydra", TabName.Hydra],
        ["software", TabName.Software],
	["publication", TabName.Publication],
	["wallaby", TabName.Wallaby],
	["vcsscatalogue",TabName.VCSSCatalogue],
        ["contact", TabName.Contact],
        ["tm", TabName.TransientMarshal],
        ["view", TabName.TransientMarshalView]
    ]);

    static get Instance() {
        return AppStore.staticInstance || new AppStore();
    }

    backendService: BackendService;
    @observable activeTab: TabName;
    @observable errorMessage: string | undefined;
    @observable loginStatus: APIStatus;
    @observable user: User | undefined; 

    constructor() {
        AppStore.staticInstance = this;
        this.backendService = new BackendService();
        this.errorMessage = undefined;
        this.loginStatus = APIStatus.Initial;
        this.user = undefined;
        if (AppStore.getAccessToken() !== null && AppStore.getRefreshToken() !== null) {
            this.loginStatus = APIStatus.Success;
        }
        if (window && window.location) {
            const currentTab = window.location.pathname.split("/");
            if (currentTab.length > 0) {
				this.activeTab = this.TabNames.get(currentTab[1]) as TabName;
            } 
        } else {
            this.activeTab = TabName.Home;
        }
    }

    public static getAccessToken(): string | null {
        return localStorage.getItem(Token.Access);
    }

    public static getRefreshToken(): string | null {
        return localStorage.getItem(Token.Refresh);
    }

    public static removeToken() {
        localStorage.removeItem(Token.Access);
        localStorage.removeItem(Token.Refresh);
    }

    public static storeAccessToken(accessToken: string) {
        localStorage.setItem(Token.Access, accessToken);
    }

    public static storeRefreshToken(refreshToken: string) {
        localStorage.setItem(Token.Refresh, refreshToken);
    }   

    @action setActiveTab (tabName: TabName) {
        this.activeTab = tabName;
    }

    @action setErrorMessage (error: string | undefined) {
        this.errorMessage = error;
    }
 
    @action setLoginStatus (status: APIStatus) {
        this.loginStatus = status;
    }

    @action async userLogin(username: string, password: string) {
        try {
            const data = {
                username: username,
                password: password
            };
            const response = await this.backendService.post("token/", data);
            AppStore.storeAccessToken(response?.data?.access);
            AppStore.storeRefreshToken(response?.data?.refresh);
            this.setLoginStatus(APIStatus.Success);
            this.setErrorMessage(undefined);
        } catch (error) {
            const response = error.response;
            this.setErrorMessage(response?.data?.detail);
            this.setLoginStatus(APIStatus.Error);
        }
    }

    @action async currentUser() {
        try {
            const token = AppStore.getAccessToken();
            if (token) {
                this.backendService.attachTokenToRequest(token); 
                await this.backendService.get("current_user").then((response) => {
                    const data = response.data;
                    // console.log(response);
                    if (response.status === 200 && data) {
                        this.user = new User(
                            data?.username, 
                            data?.email,
                            data?.first_name,
                            data?.last_name,
                            data?.date_joined,
                            data?.tm_admin,
                            data?.tm_view,
                            data?.tm_marshal,
                            data?.is_staff,
                            data?.is_active
                        );
                    }  
                }); 
            }
        } catch (e) {
            console.log(e);
        }
    }

    @action resetCurrentUser() {
        this.user = undefined;
    }

    @action clearToken = () => {
        AppStore.removeToken();
        this.loginStatus = APIStatus.Initial;
        this.backendService.clearToken();
        this.resetCurrentUser();
    };

    @computed get isAuthenticated() {
        return this.loginStatus === APIStatus.Success && AppStore.getAccessToken() !== null && AppStore.getRefreshToken() !== null;
    }

    @computed get isUser() {
        return this.user !== undefined; 
    }

    // LOGIC:
    // - if User is Marshal or admin or superuser then they can view and edit marshall classifications
    // - if something is not pending and no review requested it is considered final so the only edit a (marshal or admin or superuser) can make is to request a review (but editable still marked as false overall)
    // - if User is only a viewer then they can not see the column at all unless a classification is final (these should be sifted prior in the view QS)
    // - if User is only viewer and no classification has been made then this entire value will be None and hidden
    // - if user is Marshal or admin or superuser and no prior classification exists, then entries with all None is returned and editable and num_classifications is 0
    // - if the same author as an older classification makes another classification then the existing one is updated unless the most recent classification(largest review_number) was not them!
    // - if a Marshal just edits a classification that has review_requested True to review_requested False, but all other fields are the same a new classification is still made belonging to the current User.
    // - if another Marshal requests a review the original Marshal can not just change this to false themselves on the classification.
    // - if review is requested by another Marshal then the Marshal who made the classifcation can not update who requested review to themselves by also marking it for review.
    // - All fields must be entered so as not to be copied over by database defaults

    @computed get isMarshal(): boolean {
        return this.isUser && (this.user?.tmMarshal === true || this.user?.tmAdmin === true);
    }

    @computed get isOnlyViewer(): boolean {
        return this.isUser && this.user?.tmView === true && this.user?.tmMarshal === false && this.user?.tmAdmin === false;
    }

    public isTmFinal(pending: boolean, reviewRequested: boolean): boolean {
        return pending === false && reviewRequested === false;
    }
}
