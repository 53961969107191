import React from "react";
import { observer } from "mobx-react";
import  Table  from "antd/lib/table";
import { PageHeader, Form, Input, Button, notification, Spin, Divider, Typography, Tooltip  } from "antd";
import "./VCSSCatalogueComponent.css";

@observer
export class VCSSCatalogueComponent extends React.Component {

    public render() {

        

        const { Paragraph, Link } = Typography;
        const columns = [
            {
                title: 'Key',
                dataIndex: 'key',
                key: 'key',



            },
            {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',

            },


        ]
        const dataSource = [
           {
               key: "Central Frequency",
               value: "340 MHz",

           },
           {
            key: "Bandwidth",
            value: "33.6 MHz",

           },
           {
            key: "Angular Resolution",
            value: '~ 15"',

           },
           {
            key: "Largest Angular Size (LAS)",
            value: "8.'5",

           },
           {
            key: "Average sensitivity (1 sigma)",
            value: "3 mJy/bm",

           },
           {
            key: "Sky Coverage",
            value: "30,000 deg",

           },
           {
             key: "Sources",
             value: "52,844",
           },

        ]
        return(
            <div className="vcss">
                <PageHeader
                    className="page-header"
                    title="VCSS Epoch 1 Bright Source Catalogue"
                />
                <Paragraph>
                    The VLA Low-band Ionosphere and Transient Experiment (VLITE) is a 340 MHz commensal instrument that operates during nearly all 1-50 GHz observations on the NRAO VLA. Data are recorded on up to 18 VLITE antennas, correlated, and transferred to the US Naval Research Laboratory (NRL) for automated pipeline processing and archiving.
A special VLITE correlator mode was enabled on VLITE to allow the processing of on-the-fly (OTF) data associated with the VLA Sky Survey (VLASS). VLITE data are allowed to accumulate using a standard 2 second sampling, as the antennas move through an angular distance of 1.5 degrees. These data are then correlated, using the midpoint of the motion as the phase center. The result is short “snapshots” that have a smeared or elongated primary beam response. Each snapshot is imaged at the phase center and overlapping snapshots are corrected for the primary beam response and combined into final mosaic images. These mosaic images are processed through the VLITE Database Pipeline (VDP) into an SQL database.
{"\n"}
The first catalogue release is for the VCSS Epoch 1 Bright Source Catalogue, comprising 52,844 components cataloged above 50 sigma. This limit is set by a significant flux bias that is seen for weaker sources as described in the memo below. The Table below highlights some of the basic properties of the VCSS Epoch 1 Bright Source Catalogue.


              </Paragraph>
  <Paragraph>
            VCSS Epoch 1 Bright Catalogue Summary
             <Table dataSource = {dataSource} columns = {columns} bordered pagination = {false} showHeader={false}/>
            </Paragraph>
           <Paragraph>

              The VCSS Epoch 1 Bright Catalogue can be accessed via the following routes.
              {"\n"} 
                  <ul>
                    <li>
                    <a  href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/VCSS_Catalogue/VCSS_epoch1_BrightSourceCatalog.csv" download>
                        Direct Download link (csv format, 8.9 MB).
                    </a></li>
                   <li>
                    <a target="_blank" className="attachlink" href="https://www.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/community/taptap2/" rel="nofollow">
                        Access the CADC youcat TAP service.
                    </a>
                   You need to choose option 'youcat' in Service. The table name is cirada.VCSS.
                   You can also use the astroquery.cadc module in python or the <a target="_blank" className="attachlink" href="http://www.star.bris.ac.uk/~mbt/topcat/" rel="nofollow">
                      TOPCAT 
                   </a> interface.</li>
                   <li>
                   <a target="_blank" className="attachlink" href="http://vizier.cds.unistra.fr/viz-bin/VizieR?-source=VIII/111" rel="nofollow">
                      Vizier Link
                   </a></li></ul>

                </Paragraph>
                {"\n"}
               <Paragraph>
                The VCSS Epoch 1 Bright Source Catalogue consists of a single table as described in the Memo below. We strongly recommend users read the Memo prior to use of the catalog.
The main peculiarities of the catalog include a non-uniform resolution and sensitivity across the sky, data gaps due to incomplete coverage (Figure 1 of the Memo), a flux bias correction that must be applied to catalogued fluxes (see Section 6.1 of the Memo), and the need to add a 15% flux scale uncertainty in quadrature to the catalogued errors.
If you make use of this catalogue in your research please cite <a target="_blank" className="attachlink" href="https://iopscience.iop.org/article/10.3847/0004-637X/832/1/60/pdf" rel="nofollow">Polisensky et al. (2016)</a> and <a target="_blank" className="attachlink" href="https://www.spiedigitallibrary.org/conference-proceedings-of-spie/9906/1/Commensal-low-frequency-observing-on-the-NRAO-VLA--VLITE/10.1117/12.2233036.short?SSO=1" rel="nofollow">Clarke et al. (2016).</a>
VCSS Epoch 1 Bright Source Catalogue Memo - read before accessing data
Users of this catalogue are strongly urged to read this document in full prior to accessing the catalogue.
              <a href="https://ws.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/files/vault/cirada/VCSS_Catalogue/VCSS_Bright_Catalog_Memo.pdf" download>
                      VCSS Memo Link
                   </a>

                </Paragraph>

 
            </div>
        );
    }
}

