import { action, observable } from "mobx";
import { BackendService } from "services";
import { Service } from "models";

export enum APIStatus {
    Error = "error",
    Success = "success",
    Pending = "pending",
    Initial = "initial"
}

export class ServiceStore {
    backendService: BackendService;
    @observable dataset: Array<Service>;
    @observable status: APIStatus;

    constructor() {
        this.backendService = new BackendService();
        this.dataset = [];
        this.status = APIStatus.Initial;

        this.backendService.axiosInstance.interceptors.response.use(
            BackendService.responseInterceptor(),
            BackendService.responseErrorInterceptor()
        );
    }

    // test method for refresh method
    // @action async getTestData(token: string | null) {
    //     try {
    //         if (token) {
    //             this.backendService.attachTokenToRequest(token);
    //         }
    //         const response = await this.backendService.get("current_user");
    //         console.log(response.data);
    //     } catch (error) {
    //         console.log(error.response);
    //     }
    // }

    @action async getDataset() {
        try {
            const response = await this.backendService.get("service", "display=true");
            if (response.status >= 200 && response.status <= 300) {
                this.setAPIStatus(APIStatus.Success);
                this.setDataset(response.data);
            } else {
                this.setAPIStatus(APIStatus.Error);
            }
        } catch (error) {
            this.setAPIStatus(APIStatus.Error);
        }
    }

    @action.bound setDataset(dataset: Array<Service>) {
        if (this.dataset.length === 0) {
            dataset.forEach((data: Service) => {
                const service = new Service(data.id, data.name, data.description, data.active, data.display, data.link);
                this.dataset.push(service);
            });
        } else {
            dataset.forEach((data: Service) => {
                const result = this.getServicebyId(data);
                if (result.found) {
                    result.service.updateValue(data);
                } else {
                    const service = new Service(data.id, data.name, data.description, data.active, data.display, data.link);
                    this.dataset.push(service);
                }
            });
        }
    }

    @action.bound setAPIStatus(status: APIStatus) {
        if (this.status !== status) {
            this.status = status;
        }
    }

    private getServicebyId(service: Service): {found: boolean, service: Service} {
        for (let index = 0; index < this.dataset.length; index++) {
            const current = this.dataset[index];
            if (current.id === service.id) {
                return {found: true, service: current};
            }
        }
        return {found: false, service: service};
    }
}
