import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./ContactComponent.css";

@observer
export class ContactComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
        return(
            <div className="contact">
                <PageHeader
                    className="page-header"
                    title="Contacts"
                />

                    <div style={{display: "flex", justifyContent: "center", marginTop: "30px" }}>
                    <Paragraph style={{ fontSize: 20, textAlign: "center", fontWeight: "normal" }}>
                    Principal Investigator: Professor Bryan Gaensler (<a  href="mailto:bryan.gaensler@utoronto.ca">Email Bryan</a>)
                    </Paragraph>
                    </div>
                     <div style={{display: "flex", justifyContent: "center"}}>
                    <Paragraph style={{ fontSize: 20 , textAlign: "center", fontWeight: "normal"}}>
                    Principal Investigator: Professor Erik Rosolowsky (<a  href="mailto:rosolowsky@ualberta.ca">Email Erik</a>)
                    </Paragraph>
                    </div>
                     <div style={{display: "flex", justifyContent: "center"}}>
                     <Paragraph style={{ fontSize: 20, textAlign: "center", fontWeight: "normal" }}>
                    Project Manager: Susan Xu (<a  href="mailto:susan.xu@utoronto.ca" >Email Susan</a>)
                    </Paragraph>
                    </div>

            </div>
        );
    }
}
