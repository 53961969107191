import axios from "axios";
import { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { AppStore, TabName, APIStatus } from "stores";

export class BackendService {
    private static readonly webApiUrl = `${location.protocol}//${window.location.hostname}:${process.env.REACT_APP_DEFAULT_API_PORT}/api`;
    // private static readonly webApiUrl = "https://206.12.93.180:8000/api/";
    private _axiosInstance: AxiosInstance;

    constructor() {
        this._axiosInstance = axios.create({
            baseURL: BackendService.webApiUrl,
            timeout: 1000 * 90
        });
    }

    public get = (model: string, parameters?: string, config?: {}) => {
        let url = "";
        if (parameters) {
            url = model + "/?" + parameters;
        } else {
            url = model + "/";
        }

        if (config) {
            return this._axiosInstance.get(url, config);
        } else {
            return this._axiosInstance.get(url);
        }
    }

    public post = (model: string, data: any) => {
        return this._axiosInstance.post(model, data);
    }

    public getFakeData = (fileName: string): [] => {
        var json = require("../models/" + fileName);
        return json;
    }

    get axiosInstance() {
        return this._axiosInstance;
    }

    public attachTokenToRequest(accessToken: string) {
        this._axiosInstance.defaults.headers["Authorization"] = `JWT: ${accessToken}`;
    }

    public clearToken() {
        delete this.axiosInstance.defaults.headers["Authorization"];
    }

    public static refreshAccessToken (): Promise<string> {
        return new Promise((resolve, reject) => {
            const axiosInstance = axios.create({
                baseURL: BackendService.webApiUrl,
                timeout: 20000,
            });
            const data = {
                refresh: AppStore.getRefreshToken()
            };
            axiosInstance.post("token_refresh/", data).then(response => {
                const accessToken = response.data.access;
                AppStore.storeAccessToken(accessToken);
                AppStore.storeRefreshToken(response.data.refresh);
                resolve(accessToken);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    public static responseInterceptor = () => (response: AxiosResponse) => {
        return response;
    }

    public static responseErrorInterceptor = () => (error: AxiosError) => {
        if (error.response?.status !== 401) {
            return new Promise((resolve, reject) => {
              reject(error);
            });
        }

        // Todo disable account
        if (error.config.url === "token_refresh/") {
            AppStore.removeToken();
            AppStore.Instance.setLoginStatus(APIStatus.Initial);
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        return BackendService.refreshAccessToken().then((token: string) => {
  
            const config = error.config;
            config.headers["Authorization"] = `JWT ${token}`;
  
            return new Promise((resolve, reject) => {
                axios.request(config).then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        })
        .catch((err) => {
            Promise.reject(err);
        });
    }
}