import React from "react";
import { observer } from "mobx-react";
import { PageHeader, Typography } from "antd";
import "./FAQComponent.css";

@observer
export class FAQComponent extends React.Component {

    public render() {
        const { Paragraph, Link } = Typography;
        return(
            <div className="faq">
                <PageHeader
                    className="page-header"
                    title="Frequently Asked Questions"
                />

                    <div style={{display: "flex", justifyContent: "center", marginTop: "30px" }}>
                    <Paragraph style={{ fontSize: 20, textAlign: "center", fontWeight: "normal" }}>
                    Question: The files in the VLASS catalogue page do not download. Or I get a warning when trying to download a catalogue file.
                    </Paragraph>
                    </div>
                     <div style={{display: "flex", justifyContent: "center"}}>
                    <Paragraph style={{ fontSize: 20 , textAlign: "center", fontWeight: "normal"}}>
                    Answer: This can happen if you are using a Chrome browser. It flags or blocks some extensions. You can right click on the link and use save link as.
                            If you get the warning, choose the discard warning to download.
                    </Paragraph>
                    </div>

            </div>
        );
    }
}
